/* tslint:disable */

declare var Object: any;
export interface CertificationInterface {
  "sfdcId"?: string;
  "Cert_Lic_Number__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "Certification_License_Name__c"?: string;
  "Contact__c"?: string;
  "Expiration_Date__c"?: Date;
  "Issuing_State_Province__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Certification implements CertificationInterface {
  "sfdcId": string;
  "Cert_Lic_Number__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "Certification_License_Name__c": string;
  "Contact__c": string;
  "Expiration_Date__c": Date;
  "Issuing_State_Province__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: CertificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Certification`.
   */
  public static getModelName() {
    return "Certification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Certification for dynamic purposes.
  **/
  public static factory(data: CertificationInterface): Certification{
    return new Certification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Certification',
      plural: 'Certifications',
      path: 'Certifications',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Cert_Lic_Number__c": {
          name: 'Cert_Lic_Number__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Certification_License_Name__c": {
          name: 'Certification_License_Name__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Expiration_Date__c": {
          name: 'Expiration_Date__c',
          type: 'Date'
        },
        "Issuing_State_Province__c": {
          name: 'Issuing_State_Province__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

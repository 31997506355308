/* tslint:disable */

declare var Object: any;
export interface JobSkillsInterface {
  "sfdcId"?: string;
  "Best_Fit_Skill__c"?: boolean;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "Contact__c"?: string;
  "Experience__c"?: string;
  "ExperienceNumber__c"?: string;
  "Last_Used__c"?: string;
  "LevelPicklist__c"?: string;
  "Level__c"?: string;
  "Parsed__c"?: boolean;
  "Rating__c"?: number;
  "Skill_Name__c"?: string;
  "Type__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobSkills implements JobSkillsInterface {
  "sfdcId": string;
  "Best_Fit_Skill__c": boolean;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "Contact__c": string;
  "Experience__c": string;
  "ExperienceNumber__c": string;
  "Last_Used__c": string;
  "LevelPicklist__c": string;
  "Level__c": string;
  "Parsed__c": boolean;
  "Rating__c": number;
  "Skill_Name__c": string;
  "Type__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobSkillsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobSkills`.
   */
  public static getModelName() {
    return "JobSkills";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobSkills for dynamic purposes.
  **/
  public static factory(data: JobSkillsInterface): JobSkills{
    return new JobSkills(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobSkills',
      plural: 'JobSkills',
      path: 'JobSkills',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Best_Fit_Skill__c": {
          name: 'Best_Fit_Skill__c',
          type: 'boolean'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Experience__c": {
          name: 'Experience__c',
          type: 'string'
        },
        "ExperienceNumber__c": {
          name: 'ExperienceNumber__c',
          type: 'string'
        },
        "Last_Used__c": {
          name: 'Last_Used__c',
          type: 'string'
        },
        "LevelPicklist__c": {
          name: 'LevelPicklist__c',
          type: 'string'
        },
        "Level__c": {
          name: 'Level__c',
          type: 'string'
        },
        "Parsed__c": {
          name: 'Parsed__c',
          type: 'boolean'
        },
        "Rating__c": {
          name: 'Rating__c',
          type: 'number'
        },
        "Skill_Name__c": {
          name: 'Skill_Name__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

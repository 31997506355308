/* tslint:disable */

declare var Object: any;
export interface JobOrderInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "OwnerId"?: string;
  "RecordTypeId"?: string;
  "Any_specifications_required__c"?: string;
  "Application_Count__c"?: number;
  "Approval_Status__c"?: string;
  "Approver__c"?: string;
  "Backfill__c"?: string;
  "Backup_PM__c"?: string;
  "Basic_Qualifications__c"?: string;
  "BGV_Requirements__c"?: string;
  "Bonus__c"?: string;
  "BQSearch__c"?: string;
  "Burden_Pct__c"?: number;
  "Business_Unit_js__c"?: string;
  "CDate__c"?: Date;
  "Certifications__c"?: string;
  "Chance_this_search_will_be_filled__c"?: number;
  "Chatter_Hash_Tags__c"?: string;
  "Child_Job_Openings__c"?: number;
  "Clearance__c"?: boolean;
  "Clearance_Type__c"?: string;
  "Account__c"?: string;
  "Client_Address__c"?: string;
  "Client_Phone__c"?: string;
  "Closed_Reason__c"?: string;
  "Close_Pct__c"?: number;
  "Comment__c"?: string;
  "Confidential__c"?: boolean;
  "Confirmed_Start_Date_HR_Head__c"?: Date;
  "Contact__c"?: string;
  "Contact_Email__c"?: string;
  "Contact_Phone__c"?: string;
  "Contract__c"?: string;
  "Contract_Type__c"?: string;
  "Conversion_Date__c"?: Date;
  "Conversion_Fee__c"?: string;
  "Conversion_Fee_Pct__c"?: number;
  "Country_Code__c"?: string;
  "Country_List__c"?: string;
  "CR__c"?: string;
  "CreatedBy__c"?: string;
  "Currency__c"?: string;
  "Customer_Account__c"?: string;
  "Customer_Price__c"?: number;
  "Date_Filled__c"?: Date;
  "Date_Posted__c"?: Date;
  "Days_Gauge__c"?: string;
  "Days_Open__c"?: string;
  "Days_Open_Calculated__c"?: string;
  "Days_to_Fill__c"?: string;
  "Department_js__c"?: string;
  "Department__c"?: string;
  "Description__c"?: string;
  "Description_ID__c"?: string;
  "Apply_with_Indeed__c"?: boolean;
  "Disable_EEO__c"?: boolean;
  "Drug_Test_Requirement__c"?: string;
  "Educations__c"?: string;
  "Employment_Type__c"?: string;
  "Employment_Type_js__c"?: string;
  "Estimated_End_Date__c"?: Date;
  "Estimated_Fee__c"?: string;
  "Estimated_Gross_Margin__c"?: string;
  "Estimated_Hours__c"?: string;
  "Estimated_Start_Date__c"?: Date;
  "User__c"?: string;
  "Executive_Approver__c"?: string;
  "Expand_GEO_Search_Radius__c"?: boolean;
  "Expected_Start_Date_Recruitment_Head__c"?: Date;
  "Expiration_Date__c"?: Date;
  "Expires__c"?: boolean;
  "Facility__c"?: string;
  "Fee_Pct__c"?: number;
  "First_Year_Salary__c"?: number;
  "Forecasted_Revenue__c"?: string;
  "MetroCodes__c"?: string;
  "Good_to_go_skills__c"?: string;
  "Manager__c"?: string;
  "Hiring_Manager__c"?: string;
  "Increase_target_cost__c"?: boolean;
  "Internal_Notes__c"?: string;
  "Internal_Passing_Score__c"?: number;
  "Internal_Question_Set__c"?: string;
  "Interview_Count__c"?: number;
  "Iron_email_id_required__c"?: boolean;
  "Job_Advertisement__c"?: string;
  "Job_Assignment_Level__c"?: string;
  "Job_board_database_50_miles__c"?: number;
  "Job_Creation_Reason__c"?: string;
  "Job_Creation_Reason_Formula__c"?: string;
  "Job_Creation_Reason_Replac__c"?: string;
  "Job_Description__c"?: string;
  "Job_Family__c"?: string;
  "Job_Function__c"?: string;
  "Job_Number__c"?: string;
  "Job_Rank__c"?: string;
  "jobsite__c"?: string;
  "Jobsite_Project__c"?: string;
  "Job_Title__c"?: string;
  "Job_Title_Number__c"?: string;
  "KPI_Count__c"?: string;
  "Language_Preference__c"?: string;
  "LastModifiedBy__c"?: string;
  "Last_Modified_Date_for_S_Control__c"?: Date;
  "Legacy_JobID__c"?: string;
  "Localized_Job_Description__c"?: string;
  "Localized_Job_Title__c"?: string;
  "Location__c"?: string;
  "Location_List_New__c"?: string;
  "Location_List__c"?: string;
  "Locked__c"?: boolean;
  "Locked_Date__c"?: Date;
  "Locked_User__c"?: string;
  "Mandatory_skills__c"?: string;
  "Margin__c"?: number;
  "Markup__c"?: boolean;
  "Markup_Bill__c"?: string;
  "Markup_Pay__c"?: number;
  "Markup_Pct__c"?: number;
  "Project_Name__c"?: string;
  "Max_Bill_Rate__c"?: number;
  "Max_Conversion_Salary__c"?: number;
  "Max_Pay_Rate__c"?: number;
  "Max_Salary__c"?: number;
  "Metro__c"?: string;
  "Min_Bill_Rate__c"?: number;
  "Min_Conversion_Salary__c"?: number;
  "Min_Pay_Rate__c"?: number;
  "Min_Salary__c"?: number;
  "Negative_Keywords__c"?: string;
  "Offer_Count_JS__c"?: number;
  "Offer_Count__c"?: number;
  "Openings__c"?: number;
  "Order__c"?: string;
  "Other_Job_Creation_Reason__c"?: string;
  "Parent_Job_Order__c"?: string;
  "Passing_Score__c"?: number;
  "referral_bonus__c"?: string;
  "Percentage_Fee__c"?: number;
  "Per_Diem__c"?: boolean;
  "Per_Diem_Bill_Rate__c"?: number;
  "Per_Diem_Diff_Pct__c"?: number;
  "Per_Diem_Pay_Rate__c"?: number;
  "Placement_Count__c"?: number;
  "Placement_Count_New__c"?: number;
  "Please_Specify_BGV_and_Drug_test_Require__c"?: string;
  "Please_Specify_Local_Language__c"?: string;
  "PgMo_Name__c"?: string;
  "Population_Radius_25_Miles__c"?: number;
  "Population_Radius_50_Miles__c"?: number;
  "Posted_to_Prospects__c"?: boolean;
  "Posting_Status__c"?: string;
  "Post_Job__c"?: boolean;
  "Post_to_Chatter__c"?: boolean;
  "Post_to_DICE__c"?: boolean;
  "Recruiter__c"?: string;
  "Primary_Recruiter__c"?: string;
  "Priority__c"?: string;
  "Product__c"?: string;
  "Question_Set__c"?: string;
  "RadiusDistance__c"?: number;
  "RadiusDistanceUnit__c"?: string;
  "Reassign_job_to_new_recruitment_manager__c"?: boolean;
  "Region__c"?: string;
  "Relocate_Candidate_from_nearby_location__c"?: boolean;
  "Remaining__c"?: string;
  "Remaining_Actual__c"?: string;
  "Replacement_of_Worker__c"?: string;
  "Requisition_Issues__c"?: string;
  "Review_Term__c"?: string;
  "Salary_Range_Max__c"?: number;
  "Salary_Range_Min__c"?: number;
  "SAM_Name__c"?: string;
  "Screening_Requirements__c"?: string;
  "Search__c"?: number;
  "Search_Type__c"?: string;
  "Secondary_Recruiter__c"?: string;
  "Shared__c"?: boolean;
  "Shift__c"?: string;
  "Short_Description__c"?: string;
  "Job_Tag__c"?: string;
  "Skill_Codes__c"?: string;
  "SOW__c"?: string;
  "Specific_Requirements__c"?: string;
  "Stage__c"?: string;
  "Start_Date__c"?: string;
  "Status__c"?: string;
  "Submittal_Count__c"?: number;
  "Talent_Level__c"?: string;
  "talenttype__c"?: string;
  "Target_Base_Salary__c"?: number;
  "Target_Cost__c"?: string;
  "Term__c"?: string;
  "Term_Months__c"?: string;
  "Tertiary_Recruiter__c"?: string;
  "Text_Description__c"?: string;
  "Total_KPI_Count__c"?: string;
  "Travel__c"?: number;
  "Type_of_Requirement__c"?: string;
  "Waitlisted__c"?: boolean;
  "Work_Class__c"?: string;
  "TEST1__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobOrder implements JobOrderInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "OwnerId": string;
  "RecordTypeId": string;
  "Any_specifications_required__c": string;
  "Application_Count__c": number;
  "Approval_Status__c": string;
  "Approver__c": string;
  "Backfill__c": string;
  "Backup_PM__c": string;
  "Basic_Qualifications__c": string;
  "BGV_Requirements__c": string;
  "Bonus__c": string;
  "BQSearch__c": string;
  "Burden_Pct__c": number;
  "Business_Unit_js__c": string;
  "CDate__c": Date;
  "Certifications__c": string;
  "Chance_this_search_will_be_filled__c": number;
  "Chatter_Hash_Tags__c": string;
  "Child_Job_Openings__c": number;
  "Clearance__c": boolean;
  "Clearance_Type__c": string;
  "Account__c": string;
  "Client_Address__c": string;
  "Client_Phone__c": string;
  "Closed_Reason__c": string;
  "Close_Pct__c": number;
  "Comment__c": string;
  "Confidential__c": boolean;
  "Confirmed_Start_Date_HR_Head__c": Date;
  "Contact__c": string;
  "Contact_Email__c": string;
  "Contact_Phone__c": string;
  "Contract__c": string;
  "Contract_Type__c": string;
  "Conversion_Date__c": Date;
  "Conversion_Fee__c": string;
  "Conversion_Fee_Pct__c": number;
  "Country_Code__c": string;
  "Country_List__c": string;
  "CR__c": string;
  "CreatedBy__c": string;
  "Currency__c": string;
  "Customer_Account__c": string;
  "Customer_Price__c": number;
  "Date_Filled__c": Date;
  "Date_Posted__c": Date;
  "Days_Gauge__c": string;
  "Days_Open__c": string;
  "Days_Open_Calculated__c": string;
  "Days_to_Fill__c": string;
  "Department_js__c": string;
  "Department__c": string;
  "Description__c": string;
  "Description_ID__c": string;
  "Apply_with_Indeed__c": boolean;
  "Disable_EEO__c": boolean;
  "Drug_Test_Requirement__c": string;
  "Educations__c": string;
  "Employment_Type__c": string;
  "Employment_Type_js__c": string;
  "Estimated_End_Date__c": Date;
  "Estimated_Fee__c": string;
  "Estimated_Gross_Margin__c": string;
  "Estimated_Hours__c": string;
  "Estimated_Start_Date__c": Date;
  "User__c": string;
  "Executive_Approver__c": string;
  "Expand_GEO_Search_Radius__c": boolean;
  "Expected_Start_Date_Recruitment_Head__c": Date;
  "Expiration_Date__c": Date;
  "Expires__c": boolean;
  "Facility__c": string;
  "Fee_Pct__c": number;
  "First_Year_Salary__c": number;
  "Forecasted_Revenue__c": string;
  "MetroCodes__c": string;
  "Good_to_go_skills__c": string;
  "Manager__c": string;
  "Hiring_Manager__c": string;
  "Increase_target_cost__c": boolean;
  "Internal_Notes__c": string;
  "Internal_Passing_Score__c": number;
  "Internal_Question_Set__c": string;
  "Interview_Count__c": number;
  "Iron_email_id_required__c": boolean;
  "Job_Advertisement__c": string;
  "Job_Assignment_Level__c": string;
  "Job_board_database_50_miles__c": number;
  "Job_Creation_Reason__c": string;
  "Job_Creation_Reason_Formula__c": string;
  "Job_Creation_Reason_Replac__c": string;
  "Job_Description__c": string;
  "Job_Family__c": string;
  "Job_Function__c": string;
  "Job_Number__c": string;
  "Job_Rank__c": string;
  "jobsite__c": string;
  "Jobsite_Project__c": string;
  "Job_Title__c": string;
  "Job_Title_Number__c": string;
  "KPI_Count__c": string;
  "Language_Preference__c": string;
  "LastModifiedBy__c": string;
  "Last_Modified_Date_for_S_Control__c": Date;
  "Legacy_JobID__c": string;
  "Localized_Job_Description__c": string;
  "Localized_Job_Title__c": string;
  "Location__c": string;
  "Location_List_New__c": string;
  "Location_List__c": string;
  "Locked__c": boolean;
  "Locked_Date__c": Date;
  "Locked_User__c": string;
  "Mandatory_skills__c": string;
  "Margin__c": number;
  "Markup__c": boolean;
  "Markup_Bill__c": string;
  "Markup_Pay__c": number;
  "Markup_Pct__c": number;
  "Project_Name__c": string;
  "Max_Bill_Rate__c": number;
  "Max_Conversion_Salary__c": number;
  "Max_Pay_Rate__c": number;
  "Max_Salary__c": number;
  "Metro__c": string;
  "Min_Bill_Rate__c": number;
  "Min_Conversion_Salary__c": number;
  "Min_Pay_Rate__c": number;
  "Min_Salary__c": number;
  "Negative_Keywords__c": string;
  "Offer_Count_JS__c": number;
  "Offer_Count__c": number;
  "Openings__c": number;
  "Order__c": string;
  "Other_Job_Creation_Reason__c": string;
  "Parent_Job_Order__c": string;
  "Passing_Score__c": number;
  "referral_bonus__c": string;
  "Percentage_Fee__c": number;
  "Per_Diem__c": boolean;
  "Per_Diem_Bill_Rate__c": number;
  "Per_Diem_Diff_Pct__c": number;
  "Per_Diem_Pay_Rate__c": number;
  "Placement_Count__c": number;
  "Placement_Count_New__c": number;
  "Please_Specify_BGV_and_Drug_test_Require__c": string;
  "Please_Specify_Local_Language__c": string;
  "PgMo_Name__c": string;
  "Population_Radius_25_Miles__c": number;
  "Population_Radius_50_Miles__c": number;
  "Posted_to_Prospects__c": boolean;
  "Posting_Status__c": string;
  "Post_Job__c": boolean;
  "Post_to_Chatter__c": boolean;
  "Post_to_DICE__c": boolean;
  "Recruiter__c": string;
  "Primary_Recruiter__c": string;
  "Priority__c": string;
  "Product__c": string;
  "Question_Set__c": string;
  "RadiusDistance__c": number;
  "RadiusDistanceUnit__c": string;
  "Reassign_job_to_new_recruitment_manager__c": boolean;
  "Region__c": string;
  "Relocate_Candidate_from_nearby_location__c": boolean;
  "Remaining__c": string;
  "Remaining_Actual__c": string;
  "Replacement_of_Worker__c": string;
  "Requisition_Issues__c": string;
  "Review_Term__c": string;
  "Salary_Range_Max__c": number;
  "Salary_Range_Min__c": number;
  "SAM_Name__c": string;
  "Screening_Requirements__c": string;
  "Search__c": number;
  "Search_Type__c": string;
  "Secondary_Recruiter__c": string;
  "Shared__c": boolean;
  "Shift__c": string;
  "Short_Description__c": string;
  "Job_Tag__c": string;
  "Skill_Codes__c": string;
  "SOW__c": string;
  "Specific_Requirements__c": string;
  "Stage__c": string;
  "Start_Date__c": string;
  "Status__c": string;
  "Submittal_Count__c": number;
  "Talent_Level__c": string;
  "talenttype__c": string;
  "Target_Base_Salary__c": number;
  "Target_Cost__c": string;
  "Term__c": string;
  "Term_Months__c": string;
  "Tertiary_Recruiter__c": string;
  "Text_Description__c": string;
  "Total_KPI_Count__c": string;
  "Travel__c": number;
  "Type_of_Requirement__c": string;
  "Waitlisted__c": boolean;
  "Work_Class__c": string;
  "TEST1__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobOrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobOrder`.
   */
  public static getModelName() {
    return "JobOrder";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobOrder for dynamic purposes.
  **/
  public static factory(data: JobOrderInterface): JobOrder{
    return new JobOrder(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobOrder',
      plural: 'JobOrders',
      path: 'JobOrders',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Any_specifications_required__c": {
          name: 'Any_specifications_required__c',
          type: 'string'
        },
        "Application_Count__c": {
          name: 'Application_Count__c',
          type: 'number'
        },
        "Approval_Status__c": {
          name: 'Approval_Status__c',
          type: 'string'
        },
        "Approver__c": {
          name: 'Approver__c',
          type: 'string'
        },
        "Backfill__c": {
          name: 'Backfill__c',
          type: 'string'
        },
        "Backup_PM__c": {
          name: 'Backup_PM__c',
          type: 'string'
        },
        "Basic_Qualifications__c": {
          name: 'Basic_Qualifications__c',
          type: 'string'
        },
        "BGV_Requirements__c": {
          name: 'BGV_Requirements__c',
          type: 'string'
        },
        "Bonus__c": {
          name: 'Bonus__c',
          type: 'string'
        },
        "BQSearch__c": {
          name: 'BQSearch__c',
          type: 'string'
        },
        "Burden_Pct__c": {
          name: 'Burden_Pct__c',
          type: 'number'
        },
        "Business_Unit_js__c": {
          name: 'Business_Unit_js__c',
          type: 'string'
        },
        "CDate__c": {
          name: 'CDate__c',
          type: 'Date'
        },
        "Certifications__c": {
          name: 'Certifications__c',
          type: 'string'
        },
        "Chance_this_search_will_be_filled__c": {
          name: 'Chance_this_search_will_be_filled__c',
          type: 'number'
        },
        "Chatter_Hash_Tags__c": {
          name: 'Chatter_Hash_Tags__c',
          type: 'string'
        },
        "Child_Job_Openings__c": {
          name: 'Child_Job_Openings__c',
          type: 'number'
        },
        "Clearance__c": {
          name: 'Clearance__c',
          type: 'boolean'
        },
        "Clearance_Type__c": {
          name: 'Clearance_Type__c',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Client_Address__c": {
          name: 'Client_Address__c',
          type: 'string'
        },
        "Client_Phone__c": {
          name: 'Client_Phone__c',
          type: 'string'
        },
        "Closed_Reason__c": {
          name: 'Closed_Reason__c',
          type: 'string'
        },
        "Close_Pct__c": {
          name: 'Close_Pct__c',
          type: 'number'
        },
        "Comment__c": {
          name: 'Comment__c',
          type: 'string'
        },
        "Confidential__c": {
          name: 'Confidential__c',
          type: 'boolean'
        },
        "Confirmed_Start_Date_HR_Head__c": {
          name: 'Confirmed_Start_Date_HR_Head__c',
          type: 'Date'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "Contract__c": {
          name: 'Contract__c',
          type: 'string'
        },
        "Contract_Type__c": {
          name: 'Contract_Type__c',
          type: 'string'
        },
        "Conversion_Date__c": {
          name: 'Conversion_Date__c',
          type: 'Date'
        },
        "Conversion_Fee__c": {
          name: 'Conversion_Fee__c',
          type: 'string'
        },
        "Conversion_Fee_Pct__c": {
          name: 'Conversion_Fee_Pct__c',
          type: 'number'
        },
        "Country_Code__c": {
          name: 'Country_Code__c',
          type: 'string'
        },
        "Country_List__c": {
          name: 'Country_List__c',
          type: 'string'
        },
        "CR__c": {
          name: 'CR__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Currency__c": {
          name: 'Currency__c',
          type: 'string'
        },
        "Customer_Account__c": {
          name: 'Customer_Account__c',
          type: 'string'
        },
        "Customer_Price__c": {
          name: 'Customer_Price__c',
          type: 'number'
        },
        "Date_Filled__c": {
          name: 'Date_Filled__c',
          type: 'Date'
        },
        "Date_Posted__c": {
          name: 'Date_Posted__c',
          type: 'Date'
        },
        "Days_Gauge__c": {
          name: 'Days_Gauge__c',
          type: 'string'
        },
        "Days_Open__c": {
          name: 'Days_Open__c',
          type: 'string'
        },
        "Days_Open_Calculated__c": {
          name: 'Days_Open_Calculated__c',
          type: 'string'
        },
        "Days_to_Fill__c": {
          name: 'Days_to_Fill__c',
          type: 'string'
        },
        "Department_js__c": {
          name: 'Department_js__c',
          type: 'string'
        },
        "Department__c": {
          name: 'Department__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Description_ID__c": {
          name: 'Description_ID__c',
          type: 'string'
        },
        "Apply_with_Indeed__c": {
          name: 'Apply_with_Indeed__c',
          type: 'boolean'
        },
        "Disable_EEO__c": {
          name: 'Disable_EEO__c',
          type: 'boolean'
        },
        "Drug_Test_Requirement__c": {
          name: 'Drug_Test_Requirement__c',
          type: 'string'
        },
        "Educations__c": {
          name: 'Educations__c',
          type: 'string'
        },
        "Employment_Type__c": {
          name: 'Employment_Type__c',
          type: 'string'
        },
        "Employment_Type_js__c": {
          name: 'Employment_Type_js__c',
          type: 'string'
        },
        "Estimated_End_Date__c": {
          name: 'Estimated_End_Date__c',
          type: 'Date'
        },
        "Estimated_Fee__c": {
          name: 'Estimated_Fee__c',
          type: 'string'
        },
        "Estimated_Gross_Margin__c": {
          name: 'Estimated_Gross_Margin__c',
          type: 'string'
        },
        "Estimated_Hours__c": {
          name: 'Estimated_Hours__c',
          type: 'string'
        },
        "Estimated_Start_Date__c": {
          name: 'Estimated_Start_Date__c',
          type: 'Date'
        },
        "User__c": {
          name: 'User__c',
          type: 'string'
        },
        "Executive_Approver__c": {
          name: 'Executive_Approver__c',
          type: 'string'
        },
        "Expand_GEO_Search_Radius__c": {
          name: 'Expand_GEO_Search_Radius__c',
          type: 'boolean'
        },
        "Expected_Start_Date_Recruitment_Head__c": {
          name: 'Expected_Start_Date_Recruitment_Head__c',
          type: 'Date'
        },
        "Expiration_Date__c": {
          name: 'Expiration_Date__c',
          type: 'Date'
        },
        "Expires__c": {
          name: 'Expires__c',
          type: 'boolean'
        },
        "Facility__c": {
          name: 'Facility__c',
          type: 'string'
        },
        "Fee_Pct__c": {
          name: 'Fee_Pct__c',
          type: 'number'
        },
        "First_Year_Salary__c": {
          name: 'First_Year_Salary__c',
          type: 'number'
        },
        "Forecasted_Revenue__c": {
          name: 'Forecasted_Revenue__c',
          type: 'string'
        },
        "MetroCodes__c": {
          name: 'MetroCodes__c',
          type: 'string'
        },
        "Good_to_go_skills__c": {
          name: 'Good_to_go_skills__c',
          type: 'string'
        },
        "Manager__c": {
          name: 'Manager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "Increase_target_cost__c": {
          name: 'Increase_target_cost__c',
          type: 'boolean'
        },
        "Internal_Notes__c": {
          name: 'Internal_Notes__c',
          type: 'string'
        },
        "Internal_Passing_Score__c": {
          name: 'Internal_Passing_Score__c',
          type: 'number'
        },
        "Internal_Question_Set__c": {
          name: 'Internal_Question_Set__c',
          type: 'string'
        },
        "Interview_Count__c": {
          name: 'Interview_Count__c',
          type: 'number'
        },
        "Iron_email_id_required__c": {
          name: 'Iron_email_id_required__c',
          type: 'boolean'
        },
        "Job_Advertisement__c": {
          name: 'Job_Advertisement__c',
          type: 'string'
        },
        "Job_Assignment_Level__c": {
          name: 'Job_Assignment_Level__c',
          type: 'string'
        },
        "Job_board_database_50_miles__c": {
          name: 'Job_board_database_50_miles__c',
          type: 'number'
        },
        "Job_Creation_Reason__c": {
          name: 'Job_Creation_Reason__c',
          type: 'string'
        },
        "Job_Creation_Reason_Formula__c": {
          name: 'Job_Creation_Reason_Formula__c',
          type: 'string'
        },
        "Job_Creation_Reason_Replac__c": {
          name: 'Job_Creation_Reason_Replac__c',
          type: 'string'
        },
        "Job_Description__c": {
          name: 'Job_Description__c',
          type: 'string'
        },
        "Job_Family__c": {
          name: 'Job_Family__c',
          type: 'string'
        },
        "Job_Function__c": {
          name: 'Job_Function__c',
          type: 'string'
        },
        "Job_Number__c": {
          name: 'Job_Number__c',
          type: 'string'
        },
        "Location6__c": {
          name: 'Location6__c',
          type: 'string'
        },
        "Job_Rank__c": {
          name: 'Job_Rank__c',
          type: 'string'
        },
        "jobsite__c": {
          name: 'jobsite__c',
          type: 'string'
        },
        "Jobsite_Project__c": {
          name: 'Jobsite_Project__c',
          type: 'string'
        },
        "Job_Title__c": {
          name: 'Job_Title__c',
          type: 'string'
        },
        "Job_Title_Number__c": {
          name: 'Job_Title_Number__c',
          type: 'string'
        },
        "KPI_Count__c": {
          name: 'KPI_Count__c',
          type: 'string'
        },
        "Language_Preference__c": {
          name: 'Language_Preference__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "Last_Modified_Date_for_S_Control__c": {
          name: 'Last_Modified_Date_for_S_Control__c',
          type: 'Date'
        },
        "Legacy_JobID__c": {
          name: 'Legacy_JobID__c',
          type: 'string'
        },
        "Localized_Job_Description__c": {
          name: 'Localized_Job_Description__c',
          type: 'string'
        },
        "Localized_Job_Title__c": {
          name: 'Localized_Job_Title__c',
          type: 'string'
        },
        "Location__c": {
          name: 'Location__c',
          type: 'string'
        },
        "Location_List_New__c": {
          name: 'Location_List_New__c',
          type: 'string'
        },
        "Location_List__c": {
          name: 'Location_List__c',
          type: 'string'
        },
        "Locked__c": {
          name: 'Locked__c',
          type: 'boolean'
        },
        "Locked_Date__c": {
          name: 'Locked_Date__c',
          type: 'Date'
        },
        "Locked_User__c": {
          name: 'Locked_User__c',
          type: 'string'
        },
        "Mandatory_skills__c": {
          name: 'Mandatory_skills__c',
          type: 'string'
        },
        "Margin__c": {
          name: 'Margin__c',
          type: 'number'
        },
        "Markup__c": {
          name: 'Markup__c',
          type: 'boolean'
        },
        "Markup_Bill__c": {
          name: 'Markup_Bill__c',
          type: 'string'
        },
        "Markup_Pay__c": {
          name: 'Markup_Pay__c',
          type: 'number'
        },
        "Markup_Pct__c": {
          name: 'Markup_Pct__c',
          type: 'number'
        },
        "Project_Name__c": {
          name: 'Project_Name__c',
          type: 'string'
        },
        "Max_Bill_Rate__c": {
          name: 'Max_Bill_Rate__c',
          type: 'number'
        },
        "Max_Conversion_Salary__c": {
          name: 'Max_Conversion_Salary__c',
          type: 'number'
        },
        "Max_Pay_Rate__c": {
          name: 'Max_Pay_Rate__c',
          type: 'number'
        },
        "Max_Salary__c": {
          name: 'Max_Salary__c',
          type: 'number'
        },
        "Metro__c": {
          name: 'Metro__c',
          type: 'string'
        },
        "Min_Bill_Rate__c": {
          name: 'Min_Bill_Rate__c',
          type: 'number'
        },
        "Min_Conversion_Salary__c": {
          name: 'Min_Conversion_Salary__c',
          type: 'number'
        },
        "Min_Pay_Rate__c": {
          name: 'Min_Pay_Rate__c',
          type: 'number'
        },
        "Min_Salary__c": {
          name: 'Min_Salary__c',
          type: 'number'
        },
        "Negative_Keywords__c": {
          name: 'Negative_Keywords__c',
          type: 'string'
        },
        "Offer_Count_JS__c": {
          name: 'Offer_Count_JS__c',
          type: 'number'
        },
        "Offer_Count__c": {
          name: 'Offer_Count__c',
          type: 'number'
        },
        "Openings__c": {
          name: 'Openings__c',
          type: 'number'
        },
        "Order__c": {
          name: 'Order__c',
          type: 'string'
        },
        "Other_Job_Creation_Reason__c": {
          name: 'Other_Job_Creation_Reason__c',
          type: 'string'
        },
        "Parent_Job_Order__c": {
          name: 'Parent_Job_Order__c',
          type: 'string'
        },
        "Passing_Score__c": {
          name: 'Passing_Score__c',
          type: 'number'
        },
        "referral_bonus__c": {
          name: 'referral_bonus__c',
          type: 'string'
        },
        "Percentage_Fee__c": {
          name: 'Percentage_Fee__c',
          type: 'number'
        },
        "Per_Diem__c": {
          name: 'Per_Diem__c',
          type: 'boolean'
        },
        "Per_Diem_Bill_Rate__c": {
          name: 'Per_Diem_Bill_Rate__c',
          type: 'number'
        },
        "Per_Diem_Diff_Pct__c": {
          name: 'Per_Diem_Diff_Pct__c',
          type: 'number'
        },
        "Per_Diem_Pay_Rate__c": {
          name: 'Per_Diem_Pay_Rate__c',
          type: 'number'
        },
        "Placement_Count__c": {
          name: 'Placement_Count__c',
          type: 'number'
        },
        "Placement_Count_New__c": {
          name: 'Placement_Count_New__c',
          type: 'number'
        },
        "Please_Specify_BGV_and_Drug_test_Require__c": {
          name: 'Please_Specify_BGV_and_Drug_test_Require__c',
          type: 'string'
        },
        "Please_Specify_Local_Language__c": {
          name: 'Please_Specify_Local_Language__c',
          type: 'string'
        },
        "PgMo_Name__c": {
          name: 'PgMo_Name__c',
          type: 'string'
        },
        "Population_Radius_25_Miles__c": {
          name: 'Population_Radius_25_Miles__c',
          type: 'number'
        },
        "Population_Radius_50_Miles__c": {
          name: 'Population_Radius_50_Miles__c',
          type: 'number'
        },
        "Posted_to_Prospects__c": {
          name: 'Posted_to_Prospects__c',
          type: 'boolean'
        },
        "Posting_Status__c": {
          name: 'Posting_Status__c',
          type: 'string'
        },
        "Post_Job__c": {
          name: 'Post_Job__c',
          type: 'boolean'
        },
        "Post_to_Chatter__c": {
          name: 'Post_to_Chatter__c',
          type: 'boolean'
        },
        "Post_to_DICE__c": {
          name: 'Post_to_DICE__c',
          type: 'boolean'
        },
        "Recruiter__c": {
          name: 'Recruiter__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "Priority__c": {
          name: 'Priority__c',
          type: 'string'
        },
        "Product__c": {
          name: 'Product__c',
          type: 'string'
        },
        "Question_Set__c": {
          name: 'Question_Set__c',
          type: 'string'
        },
        "RadiusDistance__c": {
          name: 'RadiusDistance__c',
          type: 'number'
        },
        "RadiusDistanceUnit__c": {
          name: 'RadiusDistanceUnit__c',
          type: 'string'
        },
        "Reassign_job_to_new_recruitment_manager__c": {
          name: 'Reassign_job_to_new_recruitment_manager__c',
          type: 'boolean'
        },
        "Region__c": {
          name: 'Region__c',
          type: 'string'
        },
        "Relocate_Candidate_from_nearby_location__c": {
          name: 'Relocate_Candidate_from_nearby_location__c',
          type: 'boolean'
        },
        "Remaining__c": {
          name: 'Remaining__c',
          type: 'string'
        },
        "Remaining_Actual__c": {
          name: 'Remaining_Actual__c',
          type: 'string'
        },
        "Replacement_of_Worker__c": {
          name: 'Replacement_of_Worker__c',
          type: 'string'
        },
        "Requisition_Issues__c": {
          name: 'Requisition_Issues__c',
          type: 'string'
        },
        "Review_Term__c": {
          name: 'Review_Term__c',
          type: 'string'
        },
        "Salary_Range_Max__c": {
          name: 'Salary_Range_Max__c',
          type: 'number'
        },
        "Salary_Range_Min__c": {
          name: 'Salary_Range_Min__c',
          type: 'number'
        },
        "SAM_Name__c": {
          name: 'SAM_Name__c',
          type: 'string'
        },
        "Screening_Requirements__c": {
          name: 'Screening_Requirements__c',
          type: 'string'
        },
        "Search__c": {
          name: 'Search__c',
          type: 'number'
        },
        "Search_Type__c": {
          name: 'Search_Type__c',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "Shared__c": {
          name: 'Shared__c',
          type: 'boolean'
        },
        "Shift__c": {
          name: 'Shift__c',
          type: 'string'
        },
        "Short_Description__c": {
          name: 'Short_Description__c',
          type: 'string'
        },
        "Job_Tag__c": {
          name: 'Job_Tag__c',
          type: 'string'
        },
        "Skill_Codes__c": {
          name: 'Skill_Codes__c',
          type: 'string'
        },
        "SOW__c": {
          name: 'SOW__c',
          type: 'string'
        },
        "Specific_Requirements__c": {
          name: 'Specific_Requirements__c',
          type: 'string'
        },
        "Stage__c": {
          name: 'Stage__c',
          type: 'string'
        },
        "Start_Date__c": {
          name: 'Start_Date__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Submittal_Count__c": {
          name: 'Submittal_Count__c',
          type: 'number'
        },
        "Talent_Level__c": {
          name: 'Talent_Level__c',
          type: 'string'
        },
        "talenttype__c": {
          name: 'talenttype__c',
          type: 'string'
        },
        "Target_Base_Salary__c": {
          name: 'Target_Base_Salary__c',
          type: 'number'
        },
        "Target_Cost__c": {
          name: 'Target_Cost__c',
          type: 'string'
        },
        "Term__c": {
          name: 'Term__c',
          type: 'string'
        },
        "Term_Months__c": {
          name: 'Term_Months__c',
          type: 'string'
        },
        "Tertiary_Recruiter__c": {
          name: 'Tertiary_Recruiter__c',
          type: 'string'
        },
        "Text_Description__c": {
          name: 'Text_Description__c',
          type: 'string'
        },
        "Total_KPI_Count__c": {
          name: 'Total_KPI_Count__c',
          type: 'string'
        },
        "Travel__c": {
          name: 'Travel__c',
          type: 'number'
        },
        "Type_of_Requirement__c": {
          name: 'Type_of_Requirement__c',
          type: 'string'
        },
        "Waitlisted__c": {
          name: 'Waitlisted__c',
          type: 'boolean'
        },
        "Work_Class__c": {
          name: 'Work_Class__c',
          type: 'string'
        },
        "TEST1__c": {
          name: 'TEST1__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

/* tslint:disable */

declare var Object: any;
export interface JobInterviewsInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "RecordTypeId"?: string;
  "Name"?: string;
  "Account__c"?: string;
  "Client__c"?: string;
  "Address__c"?: string;
  "Candidate__c"?: string;
  "Candidate_Debrief_Notes__c"?: string;
  "Candidate_Phone__c"?: string;
  "Candidate_Type__c"?: string;
  "Client_Interview_Notes__c"?: string;
  "Code__c"?: string;
  "Contact__c"?: string;
  "Client_Contact__c"?: string;
  "Contact_Email__c"?: string;
  "Contact_Phone__c"?: string;
  "CreatedBy__c"?: string;
  "Date_Available__c"?: Date;
  "Email__c"?: string;
  "End_Date__c"?: Date;
  "Experience_Summary__c"?: string;
  "Face_to_Face__c"?: boolean;
  "HiringManager__c"?: string;
  "Hiring_Manager__c"?: string;
  "Interview_Location__c"?: string;
  "Job__c"?: string;
  "Job_Status__c"?: string;
  "LastModifiedBy__c"?: string;
  "Legacy_InterviewID__c"?: string;
  "Mobile__c"?: string;
  "Phone__c"?: boolean;
  "Phone_Number__c"?: string;
  "PrimaryRecruiter__c"?: string;
  "Primary_Recruiter__c"?: string;
  "Reason__c"?: string;
  "Reject_Detail__c"?: string;
  "Reject_Reason__c"?: string;
  "Related_Application__c"?: string;
  "Resume_Uploaded__c"?: string;
  "SecondaryRecruiter__c"?: string;
  "Secondary_Recruiter__c"?: string;
  "Start_Time__c"?: Date;
  "Status__c"?: string;
  "Substatus__c"?: string;
  "Type__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobInterviews implements JobInterviewsInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "RecordTypeId": string;
  "Name": string;
  "Account__c": string;
  "Client__c": string;
  "Address__c": string;
  "Candidate__c": string;
  "Candidate_Debrief_Notes__c": string;
  "Candidate_Phone__c": string;
  "Candidate_Type__c": string;
  "Client_Interview_Notes__c": string;
  "Code__c": string;
  "Contact__c": string;
  "Client_Contact__c": string;
  "Contact_Email__c": string;
  "Contact_Phone__c": string;
  "CreatedBy__c": string;
  "Date_Available__c": Date;
  "Email__c": string;
  "End_Date__c": Date;
  "Experience_Summary__c": string;
  "Face_to_Face__c": boolean;
  "HiringManager__c": string;
  "Hiring_Manager__c": string;
  "Interview_Location__c": string;
  "Job__c": string;
  "Job_Status__c": string;
  "LastModifiedBy__c": string;
  "Legacy_InterviewID__c": string;
  "Mobile__c": string;
  "Phone__c": boolean;
  "Phone_Number__c": string;
  "PrimaryRecruiter__c": string;
  "Primary_Recruiter__c": string;
  "Reason__c": string;
  "Reject_Detail__c": string;
  "Reject_Reason__c": string;
  "Related_Application__c": string;
  "Resume_Uploaded__c": string;
  "SecondaryRecruiter__c": string;
  "Secondary_Recruiter__c": string;
  "Start_Time__c": Date;
  "Status__c": string;
  "Substatus__c": string;
  "Type__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobInterviewsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobInterviews`.
   */
  public static getModelName() {
    return "JobInterviews";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobInterviews for dynamic purposes.
  **/
  public static factory(data: JobInterviewsInterface): JobInterviews{
    return new JobInterviews(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobInterviews',
      plural: 'JobInterviews',
      path: 'JobInterviews',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Client__c": {
          name: 'Client__c',
          type: 'string'
        },
        "Address__c": {
          name: 'Address__c',
          type: 'string'
        },
        "Candidate__c": {
          name: 'Candidate__c',
          type: 'string'
        },
        "Candidate_Debrief_Notes__c": {
          name: 'Candidate_Debrief_Notes__c',
          type: 'string'
        },
        "Candidate_Phone__c": {
          name: 'Candidate_Phone__c',
          type: 'string'
        },
        "Candidate_Type__c": {
          name: 'Candidate_Type__c',
          type: 'string'
        },
        "Client_Interview_Notes__c": {
          name: 'Client_Interview_Notes__c',
          type: 'string'
        },
        "Code__c": {
          name: 'Code__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Client_Contact__c": {
          name: 'Client_Contact__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "Control_Buttons__c": {
          name: 'Control_Buttons__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Date_Available__c": {
          name: 'Date_Available__c',
          type: 'Date'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "End_Date__c": {
          name: 'End_Date__c',
          type: 'Date'
        },
        "Experience_Summary__c": {
          name: 'Experience_Summary__c',
          type: 'string'
        },
        "Face_to_Face__c": {
          name: 'Face_to_Face__c',
          type: 'boolean',
          default: false
        },
        "HiringManager__c": {
          name: 'HiringManager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "Interview_Location__c": {
          name: 'Interview_Location__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Job_Status__c": {
          name: 'Job_Status__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "Legacy_InterviewID__c": {
          name: 'Legacy_InterviewID__c',
          type: 'string'
        },
        "Mobile__c": {
          name: 'Mobile__c',
          type: 'string'
        },
        "Phone__c": {
          name: 'Phone__c',
          type: 'boolean',
          default: false
        },
        "Phone_Number__c": {
          name: 'Phone_Number__c',
          type: 'string'
        },
        "PrimaryRecruiter__c": {
          name: 'PrimaryRecruiter__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "Reject_Detail__c": {
          name: 'Reject_Detail__c',
          type: 'string'
        },
        "Reject_Reason__c": {
          name: 'Reject_Reason__c',
          type: 'string'
        },
        "Related_Application__c": {
          name: 'Related_Application__c',
          type: 'string'
        },
        "Resume_Uploaded__c": {
          name: 'Resume_Uploaded__c',
          type: 'string'
        },
        "SecondaryRecruiter__c": {
          name: 'SecondaryRecruiter__c',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "Start_Time__c": {
          name: 'Start_Time__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Substatus__c": {
          name: 'Substatus__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

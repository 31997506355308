/* tslint:disable */

declare var Object: any;
export interface JobTemplateInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "Activities__c"?: string;
  "Answers_1__c"?: string;
  "Business_Unit__c"?: string;
  "Certificates_Licenses__c"?: string;
  "Correct_Answer_1__c"?: string;
  "Description__c"?: string;
  "Desc_Tags__c"?: string;
  "Education_and_Experience__c"?: string;
  "EEO_Code__c"?: string;
  "Essential_Duties__c"?: string;
  "Interests__c"?: string;
  "Knowledge__c"?: string;
  "Language_Skills__c"?: string;
  "Legacy_JobTemplateID__c"?: string;
  "Payroll_Job_Code__c"?: string;
  "Prepared__c"?: string;
  "Prepared_Date__c"?: Date;
  "Qualifications__c"?: string;
  "Question_1__c"?: string;
  "Question_2__c"?: string;
  "Question_3__c"?: string;
  "Rule_Out_Questions__c"?: string;
  "Salary_Low__c"?: number;
  "Skills__c"?: string;
  "SOC_code__c"?: string;
  "Supv_Responsibili__c"?: string;
  "Travel_Requirement__c"?: string;
  "Work_Context__c"?: string;
  "Work_Style__c"?: string;
  "Work_Values__c"?: string;
  "Abilities__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobTemplate implements JobTemplateInterface {
  "Name": string;
  "sfdcId": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "Activities__c": string;
  "Answers_1__c": string;
  "Business_Unit__c": string;
  "Certificates_Licenses__c": string;
  "Correct_Answer_1__c": string;
  "Description__c": string;
  "Desc_Tags__c": string;
  "Education_and_Experience__c": string;
  "EEO_Code__c": string;
  "Essential_Duties__c": string;
  "Interests__c": string;
  "Knowledge__c": string;
  "Language_Skills__c": string;
  "Legacy_JobTemplateID__c": string;
  "Payroll_Job_Code__c": string;
  "Prepared__c": string;
  "Prepared_Date__c": Date;
  "Qualifications__c": string;
  "Question_1__c": string;
  "Question_2__c": string;
  "Question_3__c": string;
  "Rule_Out_Questions__c": string;
  "Salary_Low__c": number;
  "Skills__c": string;
  "SOC_code__c": string;
  "Supv_Responsibili__c": string;
  "Travel_Requirement__c": string;
  "Work_Context__c": string;
  "Work_Style__c": string;
  "Work_Values__c": string;
  "Abilities__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobTemplateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobTemplate`.
   */
  public static getModelName() {
    return "JobTemplate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobTemplate for dynamic purposes.
  **/
  public static factory(data: JobTemplateInterface): JobTemplate{
    return new JobTemplate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobTemplate',
      plural: 'JobTemplates',
      path: 'JobTemplates',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "Activities__c": {
          name: 'Activities__c',
          type: 'string'
        },
        "Answers_1__c": {
          name: 'Answers_1__c',
          type: 'string'
        },
        "Business_Unit__c": {
          name: 'Business_Unit__c',
          type: 'string'
        },
        "Certificates_Licenses__c": {
          name: 'Certificates_Licenses__c',
          type: 'string'
        },
        "Correct_Answer_1__c": {
          name: 'Correct_Answer_1__c',
          type: 'string'
        },
        "Description__c": {
          name: 'Description__c',
          type: 'string'
        },
        "Desc_Tags__c": {
          name: 'Desc_Tags__c',
          type: 'string'
        },
        "Education_and_Experience__c": {
          name: 'Education_and_Experience__c',
          type: 'string'
        },
        "EEO_Code__c": {
          name: 'EEO_Code__c',
          type: 'string'
        },
        "Essential_Duties__c": {
          name: 'Essential_Duties__c',
          type: 'string'
        },
        "Interests__c": {
          name: 'Interests__c',
          type: 'string'
        },
        "Knowledge__c": {
          name: 'Knowledge__c',
          type: 'string'
        },
        "Language_Skills__c": {
          name: 'Language_Skills__c',
          type: 'string'
        },
        "Legacy_JobTemplateID__c": {
          name: 'Legacy_JobTemplateID__c',
          type: 'string'
        },
        "Payroll_Job_Code__c": {
          name: 'Payroll_Job_Code__c',
          type: 'string'
        },
        "Prepared__c": {
          name: 'Prepared__c',
          type: 'string'
        },
        "Prepared_Date__c": {
          name: 'Prepared_Date__c',
          type: 'Date'
        },
        "Qualifications__c": {
          name: 'Qualifications__c',
          type: 'string'
        },
        "Question_1__c": {
          name: 'Question_1__c',
          type: 'string'
        },
        "Question_2__c": {
          name: 'Question_2__c',
          type: 'string'
        },
        "Question_3__c": {
          name: 'Question_3__c',
          type: 'string'
        },
        "Rule_Out_Questions__c": {
          name: 'Rule_Out_Questions__c',
          type: 'string'
        },
        "Salary_Low__c": {
          name: 'Salary_Low__c',
          type: 'number'
        },
        "Skills__c": {
          name: 'Skills__c',
          type: 'string'
        },
        "SOC_code__c": {
          name: 'SOC_code__c',
          type: 'string'
        },
        "Supv_Responsibili__c": {
          name: 'Supv_Responsibili__c',
          type: 'string'
        },
        "Travel_Requirement__c": {
          name: 'Travel_Requirement__c',
          type: 'string'
        },
        "Work_Context__c": {
          name: 'Work_Context__c',
          type: 'string'
        },
        "Work_Style__c": {
          name: 'Work_Style__c',
          type: 'string'
        },
        "Work_Values__c": {
          name: 'Work_Values__c',
          type: 'string'
        },
        "Abilities__c": {
          name: 'Abilities__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

/* tslint:disable */

declare var Object: any;
export interface JobLocationInterface {
  "Name"?: string;
  "sfdcId"?: string;
  "OwnerId"?: string;
  "LastModifiedById"?: string;
  "CurrencyIsoCode"?: string;
  "CreatedById"?: string;
  "City__c"?: string;
  "Client__c"?: string;
  "Country__c"?: string;
  "Location__c"?: number;
  "Metro__c"?: string;
  "PostalCode__c"?: string;
  "State__c"?: string;
  "Street__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobLocation implements JobLocationInterface {
  "Name": string;
  "sfdcId": string;
  "OwnerId": string;
  "LastModifiedById": string;
  "CurrencyIsoCode": string;
  "CreatedById": string;
  "City__c": string;
  "Client__c": string;
  "Country__c": string;
  "Location__c": number;
  "Metro__c": string;
  "PostalCode__c": string;
  "State__c": string;
  "Street__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobLocationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobLocation`.
   */
  public static getModelName() {
    return "JobLocation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobLocation for dynamic purposes.
  **/
  public static factory(data: JobLocationInterface): JobLocation{
    return new JobLocation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobLocation',
      plural: 'JobLocations',
      path: 'JobLocations',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "City__c": {
          name: 'City__c',
          type: 'string'
        },
        "Client__c": {
          name: 'Client__c',
          type: 'string'
        },
        "Country__c": {
          name: 'Country__c',
          type: 'string'
        },
        "Location__c": {
          name: 'Location__c',
          type: 'number'
        },
        "Metro__c": {
          name: 'Metro__c',
          type: 'string'
        },
        "PostalCode__c": {
          name: 'PostalCode__c',
          type: 'string'
        },
        "State__c": {
          name: 'State__c',
          type: 'string'
        },
        "Street__c": {
          name: 'Street__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

/* tslint:disable */

declare var Object: any;
export interface AnswersInterface {
  "sfdcId"?: string;
  "Answer__c"?: string;
  "Legacy_AnswerID__c"?: string;
  "Order__c"?: number;
  "Question__c"?: string;
  "Score__c"?: number;
  "ts2IsTxt__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Answers implements AnswersInterface {
  "sfdcId": string;
  "Answer__c": string;
  "Legacy_AnswerID__c": string;
  "Order__c": number;
  "Question__c": string;
  "Score__c": number;
  "ts2IsTxt__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: AnswersInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Answers`.
   */
  public static getModelName() {
    return "Answers";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Answers for dynamic purposes.
  **/
  public static factory(data: AnswersInterface): Answers{
    return new Answers(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Answers',
      plural: 'Answers',
      path: 'Answers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Answer__c": {
          name: 'Answer__c',
          type: 'string'
        },
        "Legacy_AnswerID__c": {
          name: 'Legacy_AnswerID__c',
          type: 'string'
        },
        "Order__c": {
          name: 'Order__c',
          type: 'number'
        },
        "Question__c": {
          name: 'Question__c',
          type: 'string'
        },
        "Score__c": {
          name: 'Score__c',
          type: 'number'
        },
        "ts2IsTxt__c": {
          name: 'ts2IsTxt__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

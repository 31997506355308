import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ModelNames } from '../../../models/static-list-data.service';
import { DocumentService } from '../../../services/document.service';
import { AppStateService } from '../../../services/app-state.service';
import { DepartmentService } from '../../../services/department.service';

import { AccountApi, ProjectApi, DocumentApi } from '../../../sdk';
import { environment } from '../../../../environments/environment';
import { CommonService } from 'shared/services/common.service';
import { PreloaderService } from '../../../services/preloader.service';
import { GlobalFilterService } from '../../../services/global-filter.service';
import { Subscription } from 'rxjs';
import { AlertService } from 'shared/services/alert.service';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';

@Component({
    selector: 'app-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.css']
})

export class FileListComponent extends BaseVendorViewComponent implements OnInit, OnDestroy {

    selectedAccountId: string;
    @Input() allowHeaderToggle = true;
    @Input() allowFiltering = false;
    pageTitle: any;
    filterCondition: any;
    user: any;
    containerPath: string;
    userAccessType: string;
    timeZone: any;

    loadingIndicator = true;
    /*Boot-Datatable params */
    isTableData = false;
    tableData = [];
    tableResource: any;
    tableDataCount = 0;
    itemsPerPage = 10;
    isLoadMore = false;
    itemsPerBatch = 200;
    orderBy = 'createdAt DESC';
    attrContent = 'data-content';
    maxDate = new Date();
    /*ng-select param*/
    PmsAccountId = '';
    isAccountDisabled = false;
    modelNames = ModelNames;

    selectedRange: any;

    accountsTypeahead = new EventEmitter<string>();
    accounts: Array<any>;
    accountFilterItems = [];
    accountsSelected = [];

    departments: Array<any>;
    departmentsLoading = true;
    departmentsSelected = [];

    modelsList: Array<any>;
    modelsSelected = [];

    programLoading = false;
    programs: Array<any>;
    programsParents = {};
    programFilterItems = [];
    programsSelected = [];

    parentCategories = {};
    categories: Array<any>;
    categoriesFlat = {};
    categoriesLoading = true;
    categoriesSelected = [];

    subCategories: Array<any>;
    subCategoriesLoading = true;
    subCategoriesSelected = [];
    noRecords = false;
    noAccount = 'Searching...';
    isDoubleScroll = 'singleScroll';
    scrollbarH = true;
    topScroll = false;
    columns: any;
    defaultConfig: any = { _itemsPerPage: 25, _deleteDoc: true };
    globalFilters: any = {};
    globalFilterSub: Subscription;
    documentSub: Subscription;
    hidetable: boolean= false;

    totalDocuments = 0;
    enableFilters = [
        'dateOptions',
        'accounts',
        'vendorAccounts',
        'programs',
        'jobSites',
        'statgeStatus',
        'talentTypes',
        'jobs',
        'jobTypes',
        'jobStatus',
        'vendorSites',
        'caseCategory',
        'priority',
        'geoMetro',
        'workerNames',
        'pmsCaseStatus',
        'pmsCaseNumbers',
        'fileNames'
    ];
    viewAsInfo: any;
    viewAsPartner: boolean;

    constructor(
        private _cd: ChangeDetectorRef,
        private _router: Router,
        public _docService: DocumentService,
        private _departmentService: DepartmentService,
        private _appState: AppStateService,
        private _documentApi: DocumentApi,
        private _accountApi: AccountApi,
        private _projectApi: ProjectApi,
        private _alertService: AlertService,
        private _commonService: CommonService,
        private _preloaderService: PreloaderService,
        private _globalFilterService: GlobalFilterService
    ) {
        super(_appState);
        this.userAccessType = this._appState.getAccessType();
        this.selectedAccountId = this._appState.getSelectedAccount();
        this.viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
        this.viewAsPartner = (this.viewAsInfo && this.viewAsInfo.viewAsType === 'Partner');
        this.viewAsVendor = (this.viewAsInfo && this.viewAsInfo.viewAsType === 'Vendor');
        if (!this.viewAsVendor && this.viewAsPartner) { // for partner view          
            this.userAccessType = 'partner';
            this.selectedAccountId = this.viewAsInfo['account']['sfdcId'];
        } else if(this.viewAsVendor) {
            this.userAccessType = 'vendor';
            this.selectedAccountId = this.viewAsInfo.accountId;
        }
        if (this.userAccessType === 'partner') {
            this.enableFilters = this.enableFilters.filter(x => (x !== 'jobs' && x !== 'jobTypes' && x !== 'jobStatus' && x !== 'vendorSites'));
        }
        this.pageTitle = 'Document List';
        this._commonService.getUserProfile().subscribe(userProfile => {
            if (userProfile && Object.keys(userProfile).length) {
                this.user = userProfile;
                this.timeZone = userProfile['timezone'];
            }
        }, error => {
            console.log(error);
        })

        this.globalFilterSub = this._globalFilterService.getFilters().subscribe(async filters => {
            // if filter is set in local storage          
            // if (!filters.clicked) {
            //     this.globalFilters = this._commonService.getGlobalFilter(this.enableFilters);
            // }
            // while search and clear filter apply
            // if (filters.clicked) {
            //     this.globalFilters = filters.data;
            // }
            this.globalFilters = this._commonService.getGlobalFilter(this.enableFilters);
            if (this.viewAsPartner) {
                this.globalFilters['Project_SOP__c'] = { inq: [this.viewAsInfo.program.sfdcId] }
            } else if (this.userAccessType === 'partner') {
                if (!this.globalFilters['Project_SOP__c']) {
                    const programList = await this._commonService.getUserAssignedProjectList()
                    this.globalFilters['Project_SOP__c'] = {inq: programList};
                }
            }
            this.reInitialize();
            this.getDocumentList(0);
        })
    }

    ngOnInit() {
        this.containerPath = environment.baseUrl + '/' + environment.apiVersion + '/Containers/';
        if (this.allowFiltering) {
            this.searchAccountNames();
            this.getCategories();
            this.getTitles();
            this.getDepartments();
            this.accessPermission();
            this.getAllModels();
        }
        this.columns = [
            {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 40,
                minWidth: 40,
                visible: true
            }, { name: 'Action', prop: 'action', minWidth: 100, width: 100, sortable: false, resizeable: false, visible: true },
            { name: 'File Name', prop: 'Name', minWidth: 300, width: 300, sortable: true, resizeable: false, visible: true, searchable : true },
            { name: 'Account', prop: 'accountName', minWidth: 200, width: 200, sortable: true, resizeable: true, visible: true, searchable : true },
            { name: 'Program', prop: 'programName', minWidth: 200, width: 200, sortable: true, resizeable: true, visible: true, searchable : true },
            { name: 'Document Title', prop: 'subCategory', minWidth: 200, width: 200, sortable: true, resizeable: false, visible: true, searchable : true },
            { name: 'ICC Case', prop: 'iccCaseNumber', minWidth: 140, width: 140, sortable: true, resizeable: false, visible: true, searchable : true },
            { name: 'PMS Case', prop: 'pmsCaseNumber', minWidth: 140, width: 140, sortable: true, resizeable: false, visible: true, searchable : true },
            { name: 'Document Category', prop: 'category', minWidth: 250, width: 250, sortable: true, resizeable: false, visible: true, searchable : true },
            { name: 'Department', prop: 'departmentName', minWidth: 200, width: 200, resizeable: false, sortable: true, visible: true, searchable : true },
            { name: 'Related To', prop: 'modelName', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true, searchable : true },
            { name: 'Job ID', prop: 'jobNumber', minWidth: 140, width: 140, resizeable: false, sortable: true, visible: true, searchable : true },
            { name: 'Jobsite', prop: 'JobsiteName', minWidth: 200, width: 200, resizeable: false, sortable: true, visible: true, searchable : true },
            { name: 'Workorder No', prop: 'workOrderNumber', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true, searchable : true },
            { name: 'Description', prop: 'description', minWidth: 250, width: 250, showMore: true, resizeable: false, sortable: true, visible: true },
            { name: 'Uploaded By', prop: 'uploadedBy', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true, searchable : true },
            { name: 'Date Uploaded', prop: 'createdAt', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true, type: 'date' },
            { name: 'Task No', prop: 'taskNumber', minWidth: 150, width: 150, resizeable: false, sortable: true, visible: true },

        ];
        if (this.userAccessType === 'partner') {
            this.columns = this.columns.filter(x => (x.prop !== 'iccCaseNumber' && x.prop !== 'jobNumber'));
        }
        this.defaultConfig['_columns'] = this.columns;
    }

    reInitialize() {
        this.tableData = [];
        this.isLoadMore = false;
        this.isTableData = false;
        this.noRecords = false;
    }

    changeView(e) {
        if (!e.target.checked) {
            this._router.navigate(['app', 'file-manager', 'folder-view']);
            // if (this.userAccessType === 'internal') {
            //     this._router.navigate(['app', 'file-manager', 'browser']);
            //     this._router.navigate(['app', 'file-manager', 'browser', 'jobsiteView', 'Account']);
            // } else {
            //     const accountId = this.selectedAccountId;
            //     if (accountId) {
            //         this._router.navigate(['app', 'file-manager', 'browser', 'jobsiteView', 'Program', accountId]);
            //     } else {
            //         this._router.navigate(['/']);
            //     }

            // }
        }
    }

    // groupByAccount = item => item.Partner.Name;

    //    groupDocCategories = item => item.parent.title; // performs category | sub category grouping
    // Get Document Categories and Sub Categories
    getCategories() {
        this._docService.getCategories().subscribe(results => {
            this.categories = results;
            this.categoriesLoading = false;
        });
    }

    getTitles() {
        this._docService.getTitles().subscribe(results => {
            this.subCategories = [];
            results.forEach(c => {
                if (c.documentCategory) {
                    c.parent = c.documentCategory.title
                }
                this.subCategories.push(c);
            });
            this.subCategoriesLoading = false;
        });
    }

    // Get Departments
    getDepartments() {
        this._departmentService.getDepartments().subscribe(
            results => {
                this.departments = results;
                this.departmentsLoading = false;
            },
            err => {
                this.departments = [];
            }
        );
    }

    // Get Model Names
    getAllModels() {
        this.modelsList = ModelNames;
    }

    // Get Accounts filtered by name
    searchAccountNames() {
        this.accountFilterItems = [];
        this.accountsTypeahead
            .pipe(
                distinctUntilChanged(),
                debounceTime(200),
                switchMap(term =>
                    (term) ? this._accountApi.find({
                        where: { Name: { like: '%' + term.trim() + '%', options: 'i' } },
                        fields: ['Name', 'id', 'sfdcId']
                    }) : this.accountFilterItems = []
                )
            )
            .subscribe(
                x => {
                    this._cd.markForCheck();
                    this.accountFilterItems = x;
                    this.noAccount = (this.accountFilterItems.length === 0) ? 'No account found.' : '';
                },
                err => {
                    console.log(err);
                    this.accountFilterItems = [];
                    this.noAccount = '';
                }
            );
    }

    // Account change trigger
    onAccountChnage(e) {
        if (this.accountsSelected.length) {
            this.programLoading = true;
            this.loadProgramsForAccounts(this.accountsSelected);
        }
    }

    // Load master programs for selected Accounts
    loadProgramsForAccounts(accounts) {
        this.programsSelected = []; // clears previous program selection
        this._projectApi
            .getMasterProjects({
                accountId: accounts,
                fields: ['sfdcId', 'Name', 'Project__c', 'Account__c']
            })
            .subscribe(
                result => {
                    // console.log(result.programs);
                    let filteredPrograms = [];
                    result.programs.forEach(p => {
                        let obj = {
                            name: p.Name + ' (' + p.Project__c + ')',
                            id: p.sfdcId,
                            accountId: p.Account__c,
                            account: p.Partner.Name
                        };
                        filteredPrograms.push(obj);
                    });
                    this.programFilterItems = filteredPrograms;
                    this.programLoading = false;
                },
                err => {
                    this.programFilterItems = [];
                    this.programLoading = false;
                }
            );
    }

    /// to open modal
    // open(content, size) {
    //     this._modalService.open(content, size);
    //     this.isTableData = false;
    // }

    uploadModalClosed(e) {
        this.isTableData = true;
    }

    accessPermission() {
        if (this.userAccessType === 'internal') {
            this.getDocumentList(0);
        } else {
            this.isAccountDisabled = true;
            this.PmsAccountId = this.selectedAccountId;
            this.loadProgramsForAccounts([this.PmsAccountId]);
            this.filterCondition = { 'context.accountsfdcId': this.PmsAccountId, where: { isDeleted: 0 } };
            this.getDocumentList(0);
        }
    }

    getDocumentList(offset) {
        if (this.documentSub) {
            this.documentSub.unsubscribe();
        }
        const findObj = {
            limit: this.itemsPerBatch,
            order: this.orderBy,
            skip: offset,
            where: (this.allowFiltering !== undefined) ? (this.filterCondition ? this.filterCondition : {}) : {},
            jobFilter: this.globalFilters
        }
        // exclude the soft delete files
        findObj['where']['isDeleted'] = 0;
        if (this.viewAsPartner) {
            findObj['viewAsPartner'] = true;
        }
        this._preloaderService.showPreloader();
        this.documentSub = this._documentApi.getDataForListView(findObj).subscribe(
            results => {
                this._preloaderService.hidePreloader();
                this.loadingIndicator = false;
                if(results && results.message && results.message.length){
                    this._commonService.setHideFileManagerFilters(true);
                    this._alertService.warn(results.message, false, 600);
                    this.hidetable = true;
                } else {
                    this._commonService.setHideFileManagerFilters(false);
                    this.hidetable = false;
                }
                if (results.length < this.itemsPerBatch) {
                    this.noRecords = true;
                }
                if (!this.isLoadMore) {
                    this.tableData = results;
                    this.isTableData = true;
                } else {
                    results.forEach(c => {
                        this.tableData.push(c);
                    });
                    this.tableData = [...this.tableData];
                }
                if (!this.tableData.length) {
                    this.tableData = [{
                        'message': 'No data to display',
                    }];
                }
                // console.log(this.tableData);
            }, err => {
                this.isTableData = true;
                this.loadingIndicator = false;
                this.tableData = [{
                    'message': err.message,
                }];
                this._preloaderService.hidePreloader();
            }
        );
    }

    documentUploaded(event: any) {
        event.forEach(e => {
            this.tableData.unshift(e);
        });
        this.tableData = [...this.tableData];
        this.isTableData = true;
    }

    filterDocuments() {
        this.isLoadMore = false;

        let accountArr = this.accountsSelected;
        if (this.userAccessType !== 'internal') {
            accountArr = [this.PmsAccountId]
        }
        const departmentArr = this.departmentsSelected;
        const modelArr = this.modelsSelected;
        const programArr = this.programsSelected;

        const categoryArr = this.categoriesSelected;
        const subCategoryArr = this.subCategoriesSelected;
        let _dateFrom = (this.selectedRange && this.selectedRange[0])
            ? this.selectedRange[0].setSeconds(0)
            : '';
        let _dateTo = (this.selectedRange && this.selectedRange[1])
            ? this.selectedRange[1].setSeconds(59)
            : '';
        const timeZoneDate = this._commonService.getDateInterval(_dateFrom, _dateTo, '');
        let dateFrom = timeZoneDate['start'];
        let dateTo = timeZoneDate['end']
        const filterObj = {};

        if (accountArr && accountArr.length > 0) {
            filterObj['context.accountsfdcId'] = { inq: accountArr };
        }
        if (departmentArr && departmentArr.length > 0) {
            filterObj['context.departmentId'] = { inq: departmentArr };
        }
        if (modelArr && modelArr.length > 0) {
            filterObj['modelName'] = { inq: modelArr };
        }
        if (programArr && programArr.length > 0) {
            filterObj['context.programsfdcId'] = { inq: programArr };
        }
        if (categoryArr && categoryArr.length > 0) {
            filterObj['categoryId'] = { inq: categoryArr };
        }
        if (subCategoryArr && subCategoryArr.length > 0) {
            filterObj['subCategoryId'] = { inq: subCategoryArr };
        }
        if (dateFrom && dateTo) {
            filterObj['createdAt'] = { between: [dateFrom, dateTo] };
        } else if (dateFrom) {
            filterObj['createdAt'] = { gte: dateFrom };
        } else if (dateTo) {
            filterObj['createdAt'] = { lte: dateTo };
        }
        if (filterObj) {
            this.filterCondition = filterObj;
            this.isLoadMore = false;
            this.isTableData = false;
            this.noRecords = false;
            this.getDocumentList(0);
        }
    }

    loadMoreRecords() {
        this.isLoadMore = true;
        this.loadingIndicator = true;
        this.getDocumentList(this.tableData.length);
    }

    resetFilters() {
        if (this.userAccessType === 'internal') {
            this.accountsSelected = [];
        }
        this.departmentsSelected = [];
        this.modelsSelected = [];
        this.programsSelected = [];
        this.categoriesSelected = [];
        this.subCategoriesSelected = [];
        this.selectedRange = null;
        this.filterDocuments();
    }

    clearPrograms() {
        this.programFilterItems = [];
    }

    /**
    *
    * @param file
    * action list to perform download files
    */
    onActionClick(file) {
        const filePath = this.containerPath + file.fileMeta.path;
        this._commonService.download(filePath, file.fileMeta.name);
    }

    refreshDataTable() {
        this.tableData = [];
        this.getDocumentList(0);
    }

    ngOnDestroy() {
        if (this.globalFilterSub) {
            this.globalFilterSub.unsubscribe();
        }
        if (this.documentSub) {
            this.documentSub.unsubscribe();
        }
    }

}

/* tslint:disable */

declare var Object: any;
export interface JobOffersInterface {
  "sfdcId"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "RecordTypeId"?: string;
  "Name"?: string;
  "Address__c"?: string;
  "Benefits__c"?: string;
  "Bonus__c"?: number;
  "Candidate_Type__c"?: string;
  "Candidate__c"?: string;
  "Client__c"?: string;
  "Code__c"?: string;
  "Confirmed_Start_Date_HR_Head__c"?: Date;
  "Contact__c"?: string;
  "Client_Contact__c"?: string;
  "Contact_Email__c"?: string;
  "Contact_Phone__c"?: string;
  "CreatedBy__c"?: string;
  "Date_Available__c"?: Date;
  "Email__c"?: string;
  "Experience_Summary__c"?: string;
  "HiringManager__c"?: string;
  "Hiring_Manager__c"?: string;
  "Hourly__c"?: number;
  "Job__c"?: string;
  "Job_Status__c"?: string;
  "LastModifiedBy__c"?: string;
  "Legacy_OfferID__c"?: string;
  "Meals__c"?: number;
  "Mileage__c"?: number;
  "Mobile__c"?: string;
  "Offered_Title__c"?: string;
  "Phone__c"?: string;
  "PrimaryRecruiter__c"?: string;
  "Primary_Recruiter__c"?: string;
  "Reason__c"?: string;
  "Reject_Detail__c"?: string;
  "Reject_Reason__c"?: string;
  "Related_Application__c"?: string;
  "Resume_Uploaded__c"?: string;
  "Salary__c"?: number;
  "SecondaryRecruiter__c"?: string;
  "Secondary_Recruiter__c"?: string;
  "Status__c"?: string;
  "Substatus__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class JobOffers implements JobOffersInterface {
  "sfdcId": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "RecordTypeId": string;
  "Name": string;
  "Address__c": string;
  "Benefits__c": string;
  "Bonus__c": number;
  "Candidate_Type__c": string;
  "Candidate__c": string;
  "Client__c": string;
  "Code__c": string;
  "Confirmed_Start_Date_HR_Head__c": Date;
  "Contact__c": string;
  "Client_Contact__c": string;
  "Contact_Email__c": string;
  "Contact_Phone__c": string;
  "CreatedBy__c": string;
  "Date_Available__c": Date;
  "Email__c": string;
  "Experience_Summary__c": string;
  "HiringManager__c": string;
  "Hiring_Manager__c": string;
  "Hourly__c": number;
  "Job__c": string;
  "Job_Status__c": string;
  "LastModifiedBy__c": string;
  "Legacy_OfferID__c": string;
  "Meals__c": number;
  "Mileage__c": number;
  "Mobile__c": string;
  "Offered_Title__c": string;
  "Phone__c": string;
  "PrimaryRecruiter__c": string;
  "Primary_Recruiter__c": string;
  "Reason__c": string;
  "Reject_Detail__c": string;
  "Reject_Reason__c": string;
  "Related_Application__c": string;
  "Resume_Uploaded__c": string;
  "Salary__c": number;
  "SecondaryRecruiter__c": string;
  "Secondary_Recruiter__c": string;
  "Status__c": string;
  "Substatus__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: JobOffersInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobOffers`.
   */
  public static getModelName() {
    return "JobOffers";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobOffers for dynamic purposes.
  **/
  public static factory(data: JobOffersInterface): JobOffers{
    return new JobOffers(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobOffers',
      plural: 'JobOffers',
      path: 'JobOffers',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Address__c": {
          name: 'Address__c',
          type: 'string'
        },
        "Benefits__c": {
          name: 'Benefits__c',
          type: 'string'
        },
        "Bonus__c": {
          name: 'Bonus__c',
          type: 'number'
        },
        "Candidate_Type__c": {
          name: 'Candidate_Type__c',
          type: 'string'
        },
        "Candidate__c": {
          name: 'Candidate__c',
          type: 'string'
        },
        "Client__c": {
          name: 'Client__c',
          type: 'string'
        },
        "Code__c": {
          name: 'Code__c',
          type: 'string'
        },
        "Confirmed_Start_Date_HR_Head__c": {
          name: 'Confirmed_Start_Date_HR_Head__c',
          type: 'Date'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Client_Contact__c": {
          name: 'Client_Contact__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Date_Available__c": {
          name: 'Date_Available__c',
          type: 'Date'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "Experience_Summary__c": {
          name: 'Experience_Summary__c',
          type: 'string'
        },
        "HiringManager__c": {
          name: 'HiringManager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "Hourly__c": {
          name: 'Hourly__c',
          type: 'number'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Job_Status__c": {
          name: 'Job_Status__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "Legacy_OfferID__c": {
          name: 'Legacy_OfferID__c',
          type: 'string'
        },
        "Meals__c": {
          name: 'Meals__c',
          type: 'number'
        },
        "Mileage__c": {
          name: 'Mileage__c',
          type: 'number'
        },
        "Mobile__c": {
          name: 'Mobile__c',
          type: 'string'
        },
        "Offered_Title__c": {
          name: 'Offered_Title__c',
          type: 'string'
        },
        "Phone__c": {
          name: 'Phone__c',
          type: 'string'
        },
        "PrimaryRecruiter__c": {
          name: 'PrimaryRecruiter__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "Reject_Detail__c": {
          name: 'Reject_Detail__c',
          type: 'string'
        },
        "Reject_Reason__c": {
          name: 'Reject_Reason__c',
          type: 'string'
        },
        "Related_Application__c": {
          name: 'Related_Application__c',
          type: 'string'
        },
        "Resume_Uploaded__c": {
          name: 'Resume_Uploaded__c',
          type: 'string'
        },
        "Salary__c": {
          name: 'Salary__c',
          type: 'number'
        },
        "SecondaryRecruiter__c": {
          name: 'SecondaryRecruiter__c',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Substatus__c": {
          name: 'Substatus__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

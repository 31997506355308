/* tslint:disable */
import {
  Contact,
  JobApplication,
  Account
} from '../index';

declare var Object: any;
export interface JobSubmittalsInterface {
  "sfdcId"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "RecordTypeId"?: string;
  "Name"?: string;
  "Account__c"?: string;
  "AccountContact__c"?: string;
  "Address__c"?: string;
  "Available__c"?: Date;
  "Bill_Rate__c"?: number;
  "Bonus__c"?: number;
  "Candidate__c"?: string;
  "Candidate_Summary__c"?: string;
  "Candidate_Type__c"?: string;
  "Client__c"?: string;
  "Client_Submittal_Feedback__c"?: string;
  "Code__c"?: string;
  "Communication_Buttons__c"?: string;
  "Contact__c"?: string;
  "Contact_Email__c"?: string;
  "Contact_Phone__c"?: string;
  "Conversion_Fee__c"?: number;
  "Conversion_Fee_Pct__c"?: number;
  "Conversion_Salary__c"?: number;
  "Conversion_Terms__c"?: string;
  "Cover_Sheet_Completed__c"?: boolean;
  "CreatedBy__c"?: string;
  "Date_Available__c"?: Date;
  "Email__c"?: string;
  "Experienc__c"?: string;
  "Fee__c"?: number;
  "Fee_Pct__c"?: number;
  "HiringManager__c"?: string;
  "Hiring_Manager__c"?: string;
  "Initiate_SRP__c"?: boolean;
  "Job__c"?: string;
  "CreatedById"?: string;
  "Job_Status__c"?: string;
  "Key__c"?: string;
  "LastModifiedBy__c"?: string;
  "Legacy_SubmittalID__c"?: string;
  "Mobile__c"?: string;
  "Notice__c"?: string;
  "Pay_Rate__c"?: number;
  "Phone__c"?: string;
  "Pre_Screen_Completed__c"?: boolean;
  "PrimaryRecruiter__c"?: string;
  "Primary_Recruiter__c"?: string;
  "Project__c"?: string;
  "Rank__c"?: string;
  "Reason__c"?: string;
  "Reason_for_Submission__c"?: string;
  "Reject_Detail__c"?: string;
  "Reject_Reason__c"?: string;
  "Related_Application__c"?: string;
  "Resume_Attached__c"?: boolean;
  "Resume_Uploaded__c"?: string;
  "ReviewProcessed__c"?: Date;
  "ReviewSent__c"?: Date;
  "Salary__c"?: number;
  "SecondaryRecruiter__c"?: string;
  "Secondary_Recruiter__c"?: string;
  "Status__c"?: string;
  "Substatus__c"?: string;
  "Title__c"?: string;
  "Type__c"?: string;
  "id"?: number;
  "Primary_Recruiter__c​"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  candidateContact?: Contact;
  relatedApplication?: JobApplication;
  account?: Account;
  primaryRecruiter?: Contact;
}

export class JobSubmittals implements JobSubmittalsInterface {
  "sfdcId": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "RecordTypeId": string;
  "Name": string;
  "Account__c": string;
  "AccountContact__c": string;
  "Address__c": string;
  "Available__c": Date;
  "Bill_Rate__c": number;
  "Bonus__c": number;
  "Candidate__c": string;
  "Candidate_Summary__c": string;
  "Candidate_Type__c": string;
  "Client__c": string;
  "Client_Submittal_Feedback__c": string;
  "Code__c": string;
  "Communication_Buttons__c": string;
  "Contact__c": string;
  "Contact_Email__c": string;
  "Contact_Phone__c": string;
  "Conversion_Fee__c": number;
  "Conversion_Fee_Pct__c": number;
  "Conversion_Salary__c": number;
  "Conversion_Terms__c": string;
  "Cover_Sheet_Completed__c": boolean;
  "CreatedBy__c": string;
  "Date_Available__c": Date;
  "Email__c": string;
  "Experienc__c": string;
  "Fee__c": number;
  "Fee_Pct__c": number;
  "HiringManager__c": string;
  "Hiring_Manager__c": string;
  "Initiate_SRP__c": boolean;
  "Job__c": string;
  "CreatedById": string;
  "Job_Status__c": string;
  "Key__c": string;
  "LastModifiedBy__c": string;
  "Legacy_SubmittalID__c": string;
  "Mobile__c": string;
  "Notice__c": string;
  "Pay_Rate__c": number;
  "Phone__c": string;
  "Pre_Screen_Completed__c": boolean;
  "PrimaryRecruiter__c": string;
  "Primary_Recruiter__c": string;
  "Project__c": string;
  "Rank__c": string;
  "Reason__c": string;
  "Reason_for_Submission__c": string;
  "Reject_Detail__c": string;
  "Reject_Reason__c": string;
  "Related_Application__c": string;
  "Resume_Attached__c": boolean;
  "Resume_Uploaded__c": string;
  "ReviewProcessed__c": Date;
  "ReviewSent__c": Date;
  "Salary__c": number;
  "SecondaryRecruiter__c": string;
  "Secondary_Recruiter__c": string;
  "Status__c": string;
  "Substatus__c": string;
  "Title__c": string;
  "Type__c": string;
  "id": number;
  "Primary_Recruiter__c​": string;
  "createdAt": Date;
  "updatedAt": Date;
  candidateContact: Contact;
  relatedApplication: JobApplication;
  account: Account;
  primaryRecruiter: Contact;
  constructor(data?: JobSubmittalsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobSubmittals`.
   */
  public static getModelName() {
    return "JobSubmittals";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobSubmittals for dynamic purposes.
  **/
  public static factory(data: JobSubmittalsInterface): JobSubmittals{
    return new JobSubmittals(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobSubmittals',
      plural: 'JobSubmittals',
      path: 'JobSubmittals',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "AccountContact__c": {
          name: 'AccountContact__c',
          type: 'string'
        },
        "Address__c": {
          name: 'Address__c',
          type: 'string'
        },
        "Available__c": {
          name: 'Available__c',
          type: 'Date'
        },
        "Bill_Rate__c": {
          name: 'Bill_Rate__c',
          type: 'number'
        },
        "Bonus__c": {
          name: 'Bonus__c',
          type: 'number'
        },
        "Candidate__c": {
          name: 'Candidate__c',
          type: 'string'
        },
        "Candidate_Summary__c": {
          name: 'Candidate_Summary__c',
          type: 'string'
        },
        "Candidate_Type__c": {
          name: 'Candidate_Type__c',
          type: 'string'
        },
        "Client__c": {
          name: 'Client__c',
          type: 'string'
        },
        "Client_Submittal_Feedback__c": {
          name: 'Client_Submittal_Feedback__c',
          type: 'string'
        },
        "Code__c": {
          name: 'Code__c',
          type: 'string'
        },
        "Communication_Buttons__c": {
          name: 'Communication_Buttons__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "Conversion_Fee__c": {
          name: 'Conversion_Fee__c',
          type: 'number'
        },
        "Conversion_Fee_Pct__c": {
          name: 'Conversion_Fee_Pct__c',
          type: 'number'
        },
        "Conversion_Salary__c": {
          name: 'Conversion_Salary__c',
          type: 'number'
        },
        "Conversion_Terms__c": {
          name: 'Conversion_Terms__c',
          type: 'string'
        },
        "Cover_Sheet_Completed__c": {
          name: 'Cover_Sheet_Completed__c',
          type: 'boolean',
          default: false
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "Date_Available__c": {
          name: 'Date_Available__c',
          type: 'Date'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "Experienc__c": {
          name: 'Experienc__c',
          type: 'string'
        },
        "Fee__c": {
          name: 'Fee__c',
          type: 'number'
        },
        "Fee_Pct__c": {
          name: 'Fee_Pct__c',
          type: 'number'
        },
        "HiringManager__c": {
          name: 'HiringManager__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "Initiate_SRP__c": {
          name: 'Initiate_SRP__c',
          type: 'boolean',
          default: false
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "Job_Status__c": {
          name: 'Job_Status__c',
          type: 'string'
        },
        "Key__c": {
          name: 'Key__c',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "Legacy_SubmittalID__c": {
          name: 'Legacy_SubmittalID__c',
          type: 'string'
        },
        "Mobile__c": {
          name: 'Mobile__c',
          type: 'string'
        },
        "Notice__c": {
          name: 'Notice__c',
          type: 'string'
        },
        "Pay_Rate__c": {
          name: 'Pay_Rate__c',
          type: 'number'
        },
        "Phone__c": {
          name: 'Phone__c',
          type: 'string'
        },
        "Pre_Screen_Completed__c": {
          name: 'Pre_Screen_Completed__c',
          type: 'boolean',
          default: false
        },
        "PrimaryRecruiter__c": {
          name: 'PrimaryRecruiter__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "Project__c": {
          name: 'Project__c',
          type: 'string'
        },
        "Rank__c": {
          name: 'Rank__c',
          type: 'string'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "Reason_for_Submission__c": {
          name: 'Reason_for_Submission__c',
          type: 'string'
        },
        "Reject_Detail__c": {
          name: 'Reject_Detail__c',
          type: 'string'
        },
        "Reject_Reason__c": {
          name: 'Reject_Reason__c',
          type: 'string'
        },
        "Related_Application__c": {
          name: 'Related_Application__c',
          type: 'string'
        },
        "Resume_Attached__c": {
          name: 'Resume_Attached__c',
          type: 'boolean',
          default: false
        },
        "Resume_Uploaded__c": {
          name: 'Resume_Uploaded__c',
          type: 'string'
        },
        "ReviewProcessed__c": {
          name: 'ReviewProcessed__c',
          type: 'Date'
        },
        "ReviewSent__c": {
          name: 'ReviewSent__c',
          type: 'Date'
        },
        "Salary__c": {
          name: 'Salary__c',
          type: 'number'
        },
        "SecondaryRecruiter__c": {
          name: 'SecondaryRecruiter__c',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Substatus__c": {
          name: 'Substatus__c',
          type: 'string'
        },
        "Title__c": {
          name: 'Title__c',
          type: 'string'
        },
        "Type__c": {
          name: 'Type__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "Primary_Recruiter__c​": {
          name: 'Primary_Recruiter__c​',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        candidateContact: {
          name: 'candidateContact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Candidate__c',
          keyTo: 'sfdcId'
        },
        relatedApplication: {
          name: 'relatedApplication',
          type: 'JobApplication',
          model: 'JobApplication',
          relationType: 'belongsTo',
                  keyFrom: 'Related_Application__c',
          keyTo: 'sfdcId'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        primaryRecruiter: {
          name: 'primaryRecruiter',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Recruiter__c​',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreloaderService } from 'shared/services/preloader.service';
import { PasscodeApi,ContactApi, JobPrescreenApi } from 'shared/sdk';
import { AlertService } from 'shared/services/alert.service';
import { CommonService } from 'shared/services/common.service';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
@Component({
    selector: 'app-public-caq',
    templateUrl: './public-caq.component.html',
    styleUrls: ['./public-caq.component.css']
})
export class PublicCaqComponent implements OnInit {
    showCaq = false;
    applyJobInfo = {};
    passCode: any;
    errorMsg = '';
    passcodeForm: FormGroup;
    formErrors: any = {};
    validationMessages = {};
    caqLink = false;
    geneareCAQForm:FormGroup;
    formCAQErrors: any = {};
    validationCAQMessages = {};
    jobId:any;
    showQuestHead = true;
    constructor(
        private _preloaderService: PreloaderService,
        private _activatedRoute: ActivatedRoute,
        private _passcodeApi: PasscodeApi,
        private _alertService: AlertService,
        private _commonService: CommonService,
        private _fb: FormBuilder,
        private _jobPrescreenApi:JobPrescreenApi
    ) {
        this._activatedRoute.params.subscribe(params => {
            this.caqLink = false;
            if (params && params.id) {
            } else if(params && params.jobId && params.appId){
                this.caqLink = true;
                this.showQuestHead = false;
                this.jobId = params.jobId;
            } else {
                this.errorMsg = "The Provided URL has been expired.";
                this._alertService.error(this.errorMsg);
            }
        })


    }

    ngOnInit() {
        this.createForms();
        this.setFormErrors();
        this.setCAQFormErrors();
        this._commonService.saveCurrentPageAnalytics('CVMS-Public-Form-CAQ', 'Public CAQ Form');
    }
    createForms() {
        this.passcodeForm = this._fb.group({
            passcode: ['', [Validators.required]]
        });
        this.passcodeForm.valueChanges.subscribe(data => this.onFormChanged());
        this.geneareCAQForm = this._fb.group({
            UID: ['', [Validators.required]],
            Email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        });
        this.geneareCAQForm.valueChanges.subscribe(data => this.oncaqFormChanged());
    }
    onFormChanged(setDirty?: any) {
        if (!this.passcodeForm) { return; }
        const form = this.passcodeForm;
        // tslint:disable-next-line:forin
        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';
            const control = form.get(field);
            if (setDirty) {
                this.setDirty(field)
            }
            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                // tslint:disable-next-line:forin
                for (const key in control.errors) {
                    this.formErrors[field] += (messages[key]) ? messages[key] + ' ':'';
                }
            }
        }
    }
    setFormErrors() {
        this.formErrors = {
            'passcode': ''
        };
        this.validationMessages = {
            'passcode': {
                'required': 'Passcode is required.'
            }
        };
    }
    onSubmit() {
        this.onFormChanged(true);
        if (this.passcodeForm.invalid) {
            return;
        };
        this._alertService.clear();
        this._preloaderService.showPreloader();
        const _expirAt = moment().utc().format();
        let wherecond = { code: this.passcodeForm.value['passcode'] };
        wherecond['expireAt'] = { gte: _expirAt };
        this._passcodeApi.find({
            where: wherecond
        }).subscribe(res => {
            this._preloaderService.hidePreloader();
            if (res && res[0]) {
                const info = res[0];
                if(info['isSubmitted']){
                    this.errorMsg = "The CAQ Form has already been submitted.";
                    this._alertService.error(this.errorMsg); 
                    
                }else{
                    this.showCaq = true;
                    this.applyJobInfo = {
                        Internal_Question_Set__c: info['references']['queSet'],
                        appicationSfdcId: info['references']['appSfdcId'],
                        internal_Question_Set_Name: info['references']['queName'],
                        jobName: info['references']['jobName'],
                        isPublicCaq:true,
                        passcodeId:info['id']
                    } 
                }
               
            } else {
                this.errorMsg = "The Passcode has been expired or Invalid.";
                this._alertService.error(this.errorMsg);
            }

        }, err => {
            this._preloaderService.hidePreloader();
            this._alertService.error(err.message);
        });
    }

    setDirty(field) {
        this.passcodeForm.get(field).markAsTouched();
        this.passcodeForm.get(field).markAsDirty();
    }

    oncaqFormChanged(setDirty?: any) {
        if (!this.geneareCAQForm) { return; }
        const form = this.geneareCAQForm;
        // tslint:disable-next-line:forin
        for (const field in this.formCAQErrors) {
            // clear previous error message (if any)
            this.formCAQErrors[field] = '';
            const control = form.get(field);
            if (setDirty) {
                this.setCAQDirty(field)
            }
            if (control && control.dirty && !control.valid) {
                const messages = this.validationCAQMessages[field];
                // tslint:disable-next-line:forin
                for (const key in control.errors) {
                    this.formCAQErrors[field] += (messages[key]) ? messages[key] + ' ':'';
                }
            }
        }
    }
    
    setCAQFormErrors() {
        this.formCAQErrors = {
            'UID': '',
            'Email': ''
        };
        this.validationCAQMessages = {
            'passcode': {
                'required': 'Passcode is required.'
            },
            'UID': {
                'required': 'UID is required.'
            },
            'Email': {
                'required': 'Email is required.',
                'pattern': 'Please enter valid email.'
            }
        };
    }
    onSubmitCAQ() {
        this.oncaqFormChanged(true);
        if (this.geneareCAQForm.invalid) {
            return;
        };
        this._alertService.clear();
        this._preloaderService.showPreloader();
        let wherecond = { 
            User_Id__c: this.geneareCAQForm.value['UID'],
            Email:this.geneareCAQForm.value['Email']
         };
        this._jobPrescreenApi.generateCAQLink({data:{where:wherecond,fields:['sfdcId']},jobId:this.jobId}).subscribe(res => {
            this._preloaderService.hidePreloader();
            console.log(res)
            if (res) {
                this.showCaq = true;
                this.applyJobInfo = {
                    Internal_Question_Set__c: res['jobOrder']['Internal_Question_Set__c'],
                    appicationSfdcId: res['sfdcId'],
                    internal_Question_Set_Name: res['jobOrder']['internalQuestionSet']['Name'],
                    jobName: res['jobOrder']['Job_Number__c'],
                    jobTitle:res['jobOrder']['Job_Title__c'],
                    candidateName: (res['contact']) ? (res['contact']['FirstName']+' '+res['contact']['LastName']) : '',
                    isPublicCaq:true,
                    passcodeId:res['id']
                }
            } else {
                this.errorMsg = "The Data provided is not matching.";
                this._alertService.error(this.errorMsg);
            }

        }, err => {
            this._preloaderService.hidePreloader();
            this._alertService.error(err.message);
        });
    }

    setCAQDirty(field) {
        this.geneareCAQForm.get(field).markAsTouched();
        this.geneareCAQForm.get(field).markAsDirty();
    }
}

/* tslint:disable */

declare var Object: any;
export interface ApplicationAssessmentInterface {
  "sfdcId"?: string;
  "OwnerId"?: string;
  "IsDeleted"?: boolean;
  "Name"?: string;
  "CreatedById"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "LastModifiedDate"?: Date;
  "LastModifiedById"?: string;
  "LastActivityDate"?: Date;
  "Application_New__c"?: string;
  "Canceled_Date__c"?: Date;
  "Comments__c"?: string;
  "Completion_Date__c"?: Date;
  "Created_By__c"?: string;
  "Invitation_End_Date__c"?: Date;
  "Invitation_Status__c"?: string;
  "Modified_By__c"?: string;
  "Quiz__c"?: string;
  "Reviewer_Name__c"?: string;
  "Score__c"?: number;
  "Sent_Date__c"?: Date;
  "Start_Date_Time__c"?: Date;
  "Status__c"?: string;
  "TestDome_Candidate_Id__c"?: string;
  "TestReport_Url__c"?: string;
  "Test_URL__c"?: string;
  "Type_of_Test__c"?: string;
  "IRON_ExtID__c"?: string;
  "IP_Address__c"?: string;
  "Send_Email_Serviceo__c"?: boolean;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class ApplicationAssessment implements ApplicationAssessmentInterface {
  "sfdcId": string;
  "OwnerId": string;
  "IsDeleted": boolean;
  "Name": string;
  "CreatedById": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "LastModifiedDate": Date;
  "LastModifiedById": string;
  "LastActivityDate": Date;
  "Application_New__c": string;
  "Canceled_Date__c": Date;
  "Comments__c": string;
  "Completion_Date__c": Date;
  "Created_By__c": string;
  "Invitation_End_Date__c": Date;
  "Invitation_Status__c": string;
  "Modified_By__c": string;
  "Quiz__c": string;
  "Reviewer_Name__c": string;
  "Score__c": number;
  "Sent_Date__c": Date;
  "Start_Date_Time__c": Date;
  "Status__c": string;
  "TestDome_Candidate_Id__c": string;
  "TestReport_Url__c": string;
  "Test_URL__c": string;
  "Type_of_Test__c": string;
  "IRON_ExtID__c": string;
  "IP_Address__c": string;
  "Send_Email_Serviceo__c": boolean;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ApplicationAssessmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ApplicationAssessment`.
   */
  public static getModelName() {
    return "ApplicationAssessment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ApplicationAssessment for dynamic purposes.
  **/
  public static factory(data: ApplicationAssessmentInterface): ApplicationAssessment{
    return new ApplicationAssessment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ApplicationAssessment',
      plural: 'ApplicationAssessments',
      path: 'ApplicationAssessments',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "OwnerId": {
          name: 'OwnerId',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "Application_New__c": {
          name: 'Application_New__c',
          type: 'string'
        },
        "Canceled_Date__c": {
          name: 'Canceled_Date__c',
          type: 'Date'
        },
        "Comments__c": {
          name: 'Comments__c',
          type: 'string'
        },
        "Completion_Date__c": {
          name: 'Completion_Date__c',
          type: 'Date'
        },
        "Created_By__c": {
          name: 'Created_By__c',
          type: 'string'
        },
        "Invitation_End_Date__c": {
          name: 'Invitation_End_Date__c',
          type: 'Date'
        },
        "Invitation_Status__c": {
          name: 'Invitation_Status__c',
          type: 'string'
        },
        "Modified_By__c": {
          name: 'Modified_By__c',
          type: 'string'
        },
        "Quiz__c": {
          name: 'Quiz__c',
          type: 'string'
        },
        "Reviewer_Name__c": {
          name: 'Reviewer_Name__c',
          type: 'string'
        },
        "Score__c": {
          name: 'Score__c',
          type: 'number'
        },
        "Sent_Date__c": {
          name: 'Sent_Date__c',
          type: 'Date'
        },
        "Start_Date_Time__c": {
          name: 'Start_Date_Time__c',
          type: 'Date'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "TestDome_Candidate_Id__c": {
          name: 'TestDome_Candidate_Id__c',
          type: 'string'
        },
        "TestReport_Url__c": {
          name: 'TestReport_Url__c',
          type: 'string'
        },
        "Test_URL__c": {
          name: 'Test_URL__c',
          type: 'string'
        },
        "Type_of_Test__c": {
          name: 'Type_of_Test__c',
          type: 'string'
        },
        "IRON_ExtID__c": {
          name: 'IRON_ExtID__c',
          type: 'string'
        },
        "IP_Address__c": {
          name: 'IP_Address__c',
          type: 'string'
        },
        "Send_Email_Serviceo__c": {
          name: 'Send_Email_Serviceo__c',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

/* tslint:disable */

declare var Object: any;
export interface ReferralInterface {
  "sfdcId"?: string;
  "Account__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "LastModifiedById"?: string;
  "Name"?: string;
  "application_lookup__c"?: string;
  "Applied_Date__c"?: Date;
  "expire_date__c"?: Date;
  "applied__c"?: boolean;
  "internal_referral__c"?: boolean;
  "job_order_id__c"?: string;
  "message_body__c"?: string;
  "p_contact_lookup__c"?: string;
  "p_email__c"?: string;
  "p_first_name__c"?: string;
  "p_last_name__c"?: string;
  "referral_link__c"?: string;
  "r_contact_lookup__c"?: string;
  "r_email__c"?: string;
  "feedback__c"?: string;
  "r_first_name__c"?: string;
  "r_last_name__c"?: string;
  "referral_type__c"?: string;
  "Converted_to_Contact__c"?: boolean;
  "Employee_Referral__c"?: boolean;
  "Job_Order__c"?: string;
  "Prospect_Contact__c"?: string;
  "Prospect_Email__c"?: string;
  "Referral_Message__c"?: string;
  "Referrer_Contact__c"?: string;
  "Referrer_Email__c"?: string;
  "Referrer_Name__c"?: string;
  "Source__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Referral implements ReferralInterface {
  "sfdcId": string;
  "Account__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "LastModifiedById": string;
  "Name": string;
  "application_lookup__c": string;
  "Applied_Date__c": Date;
  "expire_date__c": Date;
  "applied__c": boolean;
  "internal_referral__c": boolean;
  "job_order_id__c": string;
  "message_body__c": string;
  "p_contact_lookup__c": string;
  "p_email__c": string;
  "p_first_name__c": string;
  "p_last_name__c": string;
  "referral_link__c": string;
  "r_contact_lookup__c": string;
  "r_email__c": string;
  "feedback__c": string;
  "r_first_name__c": string;
  "r_last_name__c": string;
  "referral_type__c": string;
  "Converted_to_Contact__c": boolean;
  "Employee_Referral__c": boolean;
  "Job_Order__c": string;
  "Prospect_Contact__c": string;
  "Prospect_Email__c": string;
  "Referral_Message__c": string;
  "Referrer_Contact__c": string;
  "Referrer_Email__c": string;
  "Referrer_Name__c": string;
  "Source__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: ReferralInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Referral`.
   */
  public static getModelName() {
    return "Referral";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Referral for dynamic purposes.
  **/
  public static factory(data: ReferralInterface): Referral{
    return new Referral(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Referral',
      plural: 'Referrals',
      path: 'Referrals',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "application_lookup__c": {
          name: 'application_lookup__c',
          type: 'string'
        },
        "Applied_Date__c": {
          name: 'Applied_Date__c',
          type: 'Date'
        },
        "expire_date__c": {
          name: 'expire_date__c',
          type: 'Date'
        },
        "applied__c": {
          name: 'applied__c',
          type: 'boolean'
        },
        "internal_referral__c": {
          name: 'internal_referral__c',
          type: 'boolean'
        },
        "job_order_id__c": {
          name: 'job_order_id__c',
          type: 'string'
        },
        "message_body__c": {
          name: 'message_body__c',
          type: 'string'
        },
        "p_contact_lookup__c": {
          name: 'p_contact_lookup__c',
          type: 'string'
        },
        "p_email__c": {
          name: 'p_email__c',
          type: 'string'
        },
        "p_first_name__c": {
          name: 'p_first_name__c',
          type: 'string'
        },
        "p_last_name__c": {
          name: 'p_last_name__c',
          type: 'string'
        },
        "referral_link__c": {
          name: 'referral_link__c',
          type: 'string'
        },
        "r_contact_lookup__c": {
          name: 'r_contact_lookup__c',
          type: 'string'
        },
        "r_email__c": {
          name: 'r_email__c',
          type: 'string'
        },
        "feedback__c": {
          name: 'feedback__c',
          type: 'string'
        },
        "r_first_name__c": {
          name: 'r_first_name__c',
          type: 'string'
        },
        "r_last_name__c": {
          name: 'r_last_name__c',
          type: 'string'
        },
        "referral_type__c": {
          name: 'referral_type__c',
          type: 'string'
        },
        "Converted_to_Contact__c": {
          name: 'Converted_to_Contact__c',
          type: 'boolean'
        },
        "Employee_Referral__c": {
          name: 'Employee_Referral__c',
          type: 'boolean'
        },
        "Job_Order__c": {
          name: 'Job_Order__c',
          type: 'string'
        },
        "Prospect_Contact__c": {
          name: 'Prospect_Contact__c',
          type: 'string'
        },
        "Prospect_Email__c": {
          name: 'Prospect_Email__c',
          type: 'string'
        },
        "Referral_Message__c": {
          name: 'Referral_Message__c',
          type: 'string'
        },
        "Referrer_Contact__c": {
          name: 'Referrer_Contact__c',
          type: 'string'
        },
        "Referrer_Email__c": {
          name: 'Referrer_Email__c',
          type: 'string'
        },
        "Referrer_Name__c": {
          name: 'Referrer_Name__c',
          type: 'string'
        },
        "Source__c": {
          name: 'Source__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}


import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './../shared/services/authentication.service';
import { LoopBackConfig } from './../shared/sdk/lb.config';
import { environment } from '../environments/environment';
import { Router, NavigationStart } from '@angular/router';
import { googleAnalytics } from '../assets/js/script';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { CommonService } from '../shared/services/common.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    constructor(
        private auth: AuthService,
        private router: Router,
        private globalFilter: GlobalFilterService,
        private _commonService: CommonService
    ) {
        // console.log('Application Version: ' + this.version + '(' + this.buildDate + ')');
        // disables console.logs for production
        if (environment.production) {
            this.disableAppLogs();
        }
        LoopBackConfig.setBaseURL(environment.baseUrl);
        LoopBackConfig.setApiVersion(environment.apiVersion);
        this.router.events.filter(event => event instanceof NavigationStart).subscribe(event => {
            const url = event['url'];
            if (url !== null && url !== undefined && url !== '' && url.indexOf('null') < 0) {
                googleAnalytics(url, environment.gTag_msp);
            }
            if(url !== null && url !== undefined && url !== '' && url !== '/' && url !== '/login' && !this.auth.authenticated && !url.includes('/callback#access_token=')){
                localStorage.setItem('redirectUrl', url);
            }
        });
    }

    async ngOnInit() {
        if (JSON.parse(localStorage.getItem('rememberMe')) === true) {
            this.auth.renewTokens();
        }
        if (!localStorage.getItem('filterObj')) {
            const filterObj = await this.globalFilter.setFiltersDate();
            filterObj['activityType'] = ['Case'];
            filterObj['isTestUser'] = ['false'];
            filterObj['isActiveUser'] = ['false'];
            filterObj['isServiceoLogin'] = ['true'];
            filterObj['accountCommunities'] = ['internal'];
            const filterCondition = {};
            const startDate = filterObj['dateOptions'][0];
            filterCondition['Activity_Type__c'] = { inq: ['Case'] };
            filterCondition['Account_Type__c'] = { inq: ['internal'] };
            filterCondition['isTest'] = false;
            filterCondition['isActive'] = false;
            filterCondition['isServiceoLogin'] = true;
            filterCondition['CreatedDate'] = { between: [startDate[0], startDate[1]] };
            localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
            localStorage.setItem('filterObj', JSON.stringify(filterObj));
        }
       
        let filterCondition__c = JSON.parse(localStorage.getItem('filterCondition'));
        let date = new Date();
        let currDate = this._commonService.dateFormate(date, '', '');
        let currDateJoin = currDate.substring(0,22)+currDate.substring(23,25);
        const dateFilter =filterCondition__c && filterCondition__c.CreatedDate && filterCondition__c.CreatedDate.between ?  filterCondition__c.CreatedDate.between : [];
        if(dateFilter[1] < currDateJoin){
            let filterObj__c = JSON.parse(localStorage.getItem('filterObj'));
            filterObj__c['dateOptions'][0][1] = currDateJoin;
            filterCondition__c['CreatedDate'] = { between: [dateFilter[0], currDateJoin] };
            localStorage.setItem('filterCondition', JSON.stringify(filterCondition__c));
            localStorage.setItem('filterObj', JSON.stringify(filterObj__c));            
        }
    }

    disableAppLogs() {
        if (window.console) {
            window['console']['log'] = function () { };
        }
    };
    @HostListener('window:beforeunload')
     onBeforeUnload() {
        //save current time to resume video
        this.globalFilter.updateLearningAssignment();
     }
}

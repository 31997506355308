
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'daysOpen'
  })
  export class NodaysOpenPipe implements PipeTransform {
  
    transform(data) {
      if(data.Status__c === "Open"){ //  || data.Status__c ==="Hold" || data.Status__c ==="On-Hold"
        var todaysDate = moment(data.currentDate).format("YYYY-MM-DD");
        var CDate = moment(data.CDate__c).format("YYYY-MM-DD");
        var cDays = moment(CDate, "YYYY-MM-DD");
        var toDays = moment(todaysDate, "YYYY-MM-DD");
        var result = this.getBusinessDateCount(cDays,toDays);
        return result > 0 ? (result-1) : 0;
      } else if(data.Status__c ==="Hold" || data.Status__c ==="On-Hold"){
        return '';
      } else {
        var Posted__c = moment(data.Date_Posted__c).format("YYYY-MM-DD");
        var Filled__c = moment(data.Date_Filled__c).format("YYYY-MM-DD");
        var Date_Posted = moment(Posted__c, "YYYY-MM-DD");
        var Date_Filled = moment(Filled__c, "YYYY-MM-DD");
        var result = this.getBusinessDateCount(Date_Posted,Date_Filled);
        return result > 0 ? (result-1) : 0;
      }
    }

    getBusinessDateCount(startDate, endDate) {
      var elapsed;
      var daysBeforeFirstSunday;
      var daysAfterLastSunday;
      var ifThen = function (a, b, c) {
        return a == b ? c : a;
      };  
      elapsed = endDate - startDate;
      elapsed /= 86400000;  
      daysBeforeFirstSunday = (7 - startDate.day()) % 7;
      daysAfterLastSunday = endDate.day();  
      elapsed -= (daysBeforeFirstSunday + daysAfterLastSunday);
      elapsed = (elapsed / 7) * 5;
      elapsed += ifThen(daysBeforeFirstSunday - 1, -1, 0) + ifThen(daysAfterLastSunday, 6, 5);  
      return Math.ceil(elapsed);
    }
  
  }
import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { accountCommunity, workerCType } from './../../models/static-list-data.service';
import { AlertService } from '../../services/alert.service';
import { ModalService } from '../../services/modal.service';

import { Document, DocumentApi, DocumentCategoryApi, DocumentTitleApi, FilterServiceApi, ProjectApi, DepartmentApi } from '../../sdk';

import { environment } from '../../../environments/environment';
import { AppStateService } from 'shared/services/app-state.service';
import { PreloaderService } from 'shared/services/preloader.service';

interface FormData {
    concurrency: number;
    autoUpload: boolean;
    verbose: boolean;
}

@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.css'],
})

export class DocumentUploadComponent implements OnInit {

    @Input() isPopUp = true;
    @Input() modelName: string;
    @Input() modelId: string;
    @Input() modelIds: any;
    @Input() accountName: string = '';
    @Input() programName: string = '';
    @Input() accountSfdcId: string = '';
    @Input() programSfdcId: string = '';
    @Input() Partner_Case_Number__c: string = '';
    @Input() uploadBtnLabel = 'Start Upload';
    @Input() source: string = '';    // tells about the caller component to make changes accordingly
    @Input() description = '';
    @Input() set allowedFile(e){ 
        this.uploadeVideo = e;
        this.enableBrowseToUpload();
    }
    @Input() set resumeUploadsModalIds(e){
        if (e && e.candidateId && e.appId) {
            this.candidateId = e.candidateId;
            this.applicationId = e.appId;
          }

    }
    @ViewChild('myInputFile') myInputFile: any;
    ngForm: FormGroup;
    @Input() fromAccountProfile : boolean = false;
    @Input() showBusinessUnit : boolean = true;
    @Output() uploaded = new EventEmitter<any>();
    @Output() onModalClose = new EventEmitter<any>();
    MAX_FILE_SIZE = environment.fileUpload.maxFileSize || 20971520; // maximum file size allowed for upload (20 MB)
    MAX_FILE_UPLOAD = environment.fileUpload.maxFileLimit || 5; // no. of files can be uploaded at a time

    showCategoryTitle: boolean;
    showAccountName: boolean = false;
    showProgramName: boolean = false;
    error: string;
    imgpath: string;
    sub: any;
    sfdcId: any;
    documentCategories: any;
    departments: any;
    token: any;
    isSubCategoryDisabled = true;
    isCategoryDisabled = true;
    invalidFileCount = 0;
    /// file setting
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    options: UploaderOptions;
    /// end of file setting
    /// for validation bindings
    document = {
        description: '',
        categoryId: '',
        files: '',
        parentId: '',
        department: '',
        communityId: '',
        workerType: '',
        departmentId: '',
        isSentToServiceNow: false
    };
    /// to disable close buttons
    isClose = true;
    titles: any;
    isValid = false;
    isUploadStarted = false;
    returnObj: Array<any>;
    noteMsg: any;
    errorMsgs = [];
    totalFileSize = 0;
    contentTypes = false;
    query = {
        total: 0,
        processed: 0,
        success: 0,
        error: 0
    };
    showJobOrderItemSelect = true;
    jobOrderItemDDl: any;
    accessType: any;
    allowedCategoryList: any;
    allowedSubCategoryList: any;
    uploadeVideo = 0;
    formatMsg = 'We Accept files in all format!';
    doNoAllowToUpload = true;
    isAccountDropdown = false;
    isProgramDropdown = false;
    accounts = [];
    projects = [];
    accountId = "";
    prgName = "";
    accountCommunityValues: any;
    workerCType: any;
    requireCommunity = false;
    docUploadedByUser = [];
    avoidToUploadForUser = false;
    candidateId: '';
    applicationId: '';

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private _modalService: ModalService,
        private documentCategoryApi: DocumentCategoryApi,
        private _documentApi: DocumentApi,
        private _documentTitleApi: DocumentTitleApi,
        private _appState: AppStateService,
        private _filterServiceApi: FilterServiceApi,
        private _projectApi:ProjectApi,
        private _departmentApi: DepartmentApi,
        private _loader: PreloaderService,

    ) {
        // file setting 
        this.formData = {
            concurrency: 0,
            autoUpload: false,
            verbose: true
        };
        if (this.source == 'documentForTraining') {
            this.MAX_FILE_SIZE = 104857600;
        }

        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
        this.humanizeBytes = humanizeBytes;
        this.returnObj = [];
        this.options = {
            concurrency: 1,
            maxUploads: this.MAX_FILE_UPLOAD,
            allowedContentTypes: ['*'],
            maxFileSize: this.MAX_FILE_SIZE
        }
        // end of file setting
        this.sub = this.route.params.subscribe(params => {
            this.sfdcId = params['id'];
        });
        this.imgpath = environment.baseUrl + '/' + environment.apiVersion;
        this.token = localStorage.getItem('accessToken');
        const filePermissions = this._appState.getFileManagerPermissions();
        this.allowedCategoryList = (filePermissions['uploads'] && filePermissions['uploads']['allowedCategoryList']) ? filePermissions['uploads']['allowedCategoryList'] : [];
        this.allowedSubCategoryList = (filePermissions['uploads'] && filePermissions['uploads']['allowedSubCategoryList']) ? filePermissions['uploads']['allowedSubCategoryList'] : [];
    }

    ngOnInit() {
        if (this.modelName === 'Task') {
        //     this.showAccountName = true;
        //     this.showProgramName = true;
         } else {
            this.showAccountName = false;
            this.showProgramName = false;
        }
        this.error = '';
        this.accountCommunityValues = accountCommunity;
        this.workerCType = workerCType;
        // fix to prevent file drops out of target container
        window.addEventListener('drop', function (e) {
            e.preventDefault();
        }, false);
        window.addEventListener('dragover', function (e) {
            e.preventDefault();
        }, false);

        if (this.modelId && typeof this.modelId === 'object') {
            if (this.modelName === 'JobOrderItem') {
                this.showJobOrderItemSelect = true;
                this.jobOrderItemDDl = this.modelId && this.modelId[0] && this.modelId[0]['jobOrderItemDDls'] ?
                    this.modelId[0]['jobOrderItemDDls'] : '';
                this.modelIds = this.modelId && this.modelId[0] && this.modelId[0]['jobOrderItem'] &&
                    this.modelId[0]['jobOrderItem'];
                this.modelId = '';

            } else if (this.modelName === 'Task') {
                this.showJobOrderItemSelect = false;
                this.modelId = this.modelId && this.modelId[0] && this.modelId[0]['Task'];
            } else if (this.modelName === 'MilestoneLibrary') {
                this.showJobOrderItemSelect = false;
                this.modelId = this.modelId && this.modelId[0] && this.modelId[0]['MilestoneLibrary'];
            } else {
                this.modelId = this.modelId && this.modelId[0] && this.modelId[0]['Job'] && this.modelId[0]['Job'][0]
            }
        }

        // if (this.modelName === 'JobOrderItem') {
        //     this.showJobOrderItemSelect = true;
        //     if (this.modelIds && this.modelIds.length > 0) {
        //         this.modelIds = this.modelIds[0]['jobOrderItem']
        //     }
        // }

        if (!this.modelIds) {
            this.modelIds = []
        }
        if (this.source && (this.source === 'MessagePost' || this.source === 'Task-Conversation' || this.source === 'Task' || this.source === 'Timecard' || this.source === 'Timesheet' || this.source === 'JobOrderDetail' || this.source === 'UserVideo' 
        || this.source === 'documentForTraining' || this.source === 'mwpMessagePost' || this.source === 'applicationFiles')) {
            if (this.source === 'MessagePost') {
                this.showCategoryTitle = false;
                this.getDocumentCategoryForSource(this.source);
            } else if (this.source === 'Timecard' || this.source === 'Timesheet') {
                this.showCategoryTitle = false;
                this.document.parentId = '19';
                this.document.categoryId = this.source === 'Timecard' ? '155' : '157';
                this.setAllowedFileTypes(this.document.parentId);
            } else if (this.source === 'Task-Conversation') {
                this.showCategoryTitle = false;
                this.getDocumentCategoryForSource(this.source);
            } else if (this.source === 'Task') {
                this.showCategoryTitle = true;
                this.getDocumentCategories(false);
                //this.getDocumentCategoryForSource(this.source);
            } else if (this.source === 'JobOrderDetail') {
                this.showCategoryTitle = false;
                this.document.parentId = '20'; // Recruitment Manager(VMS)(20)
                this.document.categoryId = '158'; // Recruitment Manager(VMS)(158)
            } else if (this.source === 'UserVideo') {
                this.showCategoryTitle = false;
                this.document.categoryId = '159';
                this.document.parentId = '21';
                const allowedExtensions =  ['video/mp4'];
                const extensionsForUser =  ['.mp4'];
                this.options.allowedContentTypes = allowedExtensions;
                this.options.maxUploads = 3;
                this.MAX_FILE_UPLOAD = 3-this.uploadeVideo;
                this.MAX_FILE_SIZE = 10485760;
                this.options.maxFileSize = 10485760;
                const appData = JSON.parse(localStorage.getItem('appData'));
                this.modelId = (appData && appData.user && appData.user.id) ? appData.user.id : '';
                this.noteMsg = 'Allowed File Extensions are: (' + ((extensionsForUser.length) ? extensionsForUser.join(', ') : 'All') + ')';
                this.formatMsg = 'We Accept only Video files!';
            } else if (this.source === 'documentForTraining') {
                this.MAX_FILE_SIZE = 104857600;
                this.options.maxFileSize = 104857600;
                this.showAccountName = true;
                this.showProgramName = true;
                this.isAccountDropdown = true;
                this.isProgramDropdown = true;
                this.showCategoryTitle = true;
                this.requireCommunity = true;
                this.getDocumentCategories(false);
                this.getPartnerAccount();
                this.getDepartments();
            } else if (this.source === 'mwpMessagePost') {
                this.showCategoryTitle = false;
                this.document.parentId = '23';
                this.document.categoryId = '178';
                this.setAllowedFileTypes(this.document.parentId);
            }else if (this.source === 'applicationFiles') {
                this.getRecruitmentMangerSubCategories();
                this.showBusinessUnit = false;
                this.showCategoryTitle = false;
                // this.document.categoryId = '168';
                this.document.parentId = '20';
                this.noteMsg = 'Allowed File Extensions are: (All)';
                this.options.allowedContentTypes = ['*'];
                this.formatMsg = '';
                this.options.maxUploads = 1;
                this.MAX_FILE_UPLOAD = 1;
            }
        } else {
            this.showCategoryTitle = true;
            if (this.showJobOrderItemSelect && this.modelIds.length > 0) {
                this.getDocumentCategories(true);
            } else {
                this.getDocumentCategories();
            }
        }

        this.query.total = 0;
        this.query.processed = 0;
        this.query.success = 0;
        this.query.error = 0;
        this.document.description =  this.description ? this.description : this.document.description;
        this.setPmsAccessPermission();
    }

    setPmsAccessPermission() {
        this.accessType = this._appState.getAccessType();
        // To check if view as PMS mode is activated.
        if (localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
        }
        if (this.accessType === 'partner') {
            this.noteMsg = 'Allowed File Extensions are: (All)';
            this.options.allowedContentTypes = ['*'];
            this.document.parentId = '15'; // Service Operations Management (15)
            this.document.categoryId = '128'; // Job Status Update (128)
            // this.showCategoryTitle = false;
            console.log(this.options.allowedContentTypes, "partner")
        }
    }

    /**
     * to check allowed file types based on admin settings
     */
    setAllowedFileTypes(categoryId) {
        this.noteMsg = '';
        this.files = [];
        let allowedFileTypes;
        if (categoryId) {
            if (this.documentCategories && this.documentCategories.length) {
                allowedFileTypes = this.documentCategories.filter(category => {
                    return category.id == categoryId;
                });
            }
            const allowedExtensions = (allowedFileTypes && allowedFileTypes.length && allowedFileTypes[0]['allowedFileTypes']) ?
                allowedFileTypes[0]['allowedFileTypes'].replace(/;/g, ',').split(',') : [];
            const allowedContentTypes = (allowedExtensions.length) ? allowedExtensions : ['*'];
            this.noteMsg = 'Allowed File Extensions are: (' + ((allowedExtensions.length) ? allowedExtensions.join(', ') : 'All') + ')';
            this.options.allowedContentTypes = allowedContentTypes;
        }
    }

    getDocumentCategories(disable?) {
        this.isCategoryDisabled = disable || true;
        this.documentCategories = [];
        let whereFilter = {
            isActive: 1,
        }
        if (this.modelName === 'Task') {
            whereFilter['modelName'] = 'Task';
        } else if(this.modelName === 'Program'){
            whereFilter['modelName'] = { inq: ['Program']};
            whereFilter['id'] = { inq: [24,25,27,28,29,30]};
        } else {
            if(!this.fromAccountProfile){
            whereFilter['modelName'] = { inq: [(this.modelName === 'MilestoneLibrary') ? 'Job' : this.modelName, null, '*'] };
            }
        }
        if (this.allowedCategoryList && this.allowedCategoryList.length) {
            if(this.modelName !== 'Program') {
                whereFilter['id'] = { inq: this.allowedCategoryList };
            }
            this.documentCategoryApi
                .find({
                    where: whereFilter
                })
                .subscribe(results => {
                    this.documentCategories = results;
                    this.isCategoryDisabled = disable || false;
                });
        }

    }

    /**
     * get Sub Categories on category selection.
     */
    getSubCategories(categoryId) {
        this.isSubCategoryDisabled = true;
        this.document.categoryId = '';
        this.document.files = '';
        this.titles = [];
        this.files = [];
        this.totalFileSize = 0;
        this.contentTypes = false;
        this.removeAllFiles();
        this.isValid = false;
        const findObj = {
            where: {
                isActive: 1,
                documentCategoryId: categoryId
            }
        }
        if (categoryId !== '' && this.allowedSubCategoryList && this.allowedSubCategoryList.length) {
            findObj['where']['id'] = { inq: this.allowedSubCategoryList };
            this._documentTitleApi
                .find(findObj)
                .subscribe(data => {
                    this.titles = data;
                    this.isSubCategoryDisabled = false;
                    this.setAllowedFileTypes(categoryId);
                });
        }
    }

    getDocumentCategoryForSource(source) {
        let docCategoryFilter = {};
        if (source === 'MessagePost') {
            docCategoryFilter = { title: 'Service Operations Management', isActive: 1 };
            if (this.modelName === 'Order' || this.modelName === 'PurchaseOrder') {
                docCategoryFilter['title'] = 'Partner Account Management';
            }
        } else if (source === 'Task-Conversation' || source === 'Task') {
            docCategoryFilter = { title: 'Compliance Management', isActive: 1 };
        }
        if (this.allowedCategoryList && this.allowedCategoryList.length) {
            docCategoryFilter['id'] = { inq: this.allowedCategoryList };
        }
        this.documentCategoryApi.find({
            where: docCategoryFilter
        }).subscribe(result => {
            if (result && result.length) {
                this.documentCategories = result;
                this.document.parentId = result[0]['id'];

                let documentTitleFilter = {};
                if (source === 'MessagePost') {
                    documentTitleFilter = { title: 'Job Status Update', documentCategoryId: result[0]['id'], isActive: 1 };
                    if (this.modelName === 'Order') {
                        documentTitleFilter['title'] = 'Sales order';
                    } else if (this.modelName === 'PurchaseOrder') {
                        documentTitleFilter['title'] = 'Customer Purchase Order';
                    }
                } else if (source === 'Task-Conversation' || source === 'Task') {
                    documentTitleFilter = { title: 'Task Conversation', documentCategoryId: result[0]['id'], isActive: 1 };
                }
                if (this.allowedSubCategoryList && this.allowedSubCategoryList.length) {
                    documentTitleFilter['id'] = { inq: this.allowedSubCategoryList };
                }
                this._documentTitleApi.find({
                    where: documentTitleFilter
                }).subscribe(response => {
                    if (response && response.length) {
                        this.titles = response;
                        this.document.categoryId = response[0]['id'];
                        this.setAllowedFileTypes(this.document.parentId);
                        this.getContentTypes();
                    } else {
                        this.error = 'Document Title not found.';
                    }
                }, error => {
                    this.error = error.message;
                });
            } else {
                this.error = 'You are not allow to upload file(s).';
            }
        }, err => {
            this.error = err.message;
        })
    }

    validateFileSize(file) {
        if (file) {
            this.files.push(file);
        }
        this.isValid = (this.files.length > this.MAX_FILE_UPLOAD) ? false : true;
        if (this.files.length > this.MAX_FILE_UPLOAD) {
            this.error = 'Only ' + this.MAX_FILE_UPLOAD + ' Files are allowed to upload at a time.';
        }
        this.query.total = this.files.length;
    }

    onUploadOutput(output: UploadOutput, model: Document): void {
        switch (output.type) {
            case 'allAddedToQueue':
                // uncomment this if you want to auto upload files when added
                // const event: UploadInput = {
                //   type: 'uploadAll',
                //   url: '/upload',
                //   method: 'POST',
                //   data: { foo: 'bar' }
                // };
                // this.uploadInput.emit(event);
                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined') {
                    output.file['sizeMB'] = this.humanizeBytes(output.file.size);
                    // Validate File For Size before adding
                    this.validateFileSize(output.file);
                    if (this.modelName === 'UserProfile'){
                        this.enableBrowseToUpload();
                    }
                }
                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files[index] = output.file;
                }
                break;
            case 'removed':
                // remove file from array when removed
                this.files = this.files.filter((file: UploadFile) => file !== output.file);
                this.query.total--;
                break;
            case 'dragOver':
                this.dragOver = true;
                break;
            case 'dragOut':
            case 'drop':
                this.dragOver = false;
                break;
            case 'rejected':
                console.log(output.file.name + '(' + this.humanizeBytes(output.file.size) + ')' + ' rejected');
                this.error = 'Error. Please check your file upload criteria ! <br/> 1. Only '
                    + this.MAX_FILE_UPLOAD + ' Files are allowed to upload at a time.<br/>2. Maximum size allowed for a file is '
                    + this.humanizeBytes(this.MAX_FILE_SIZE) + '.<br/>3. ' + this.noteMsg;
                // enhancement -> showing error message to 5s
                setTimeout(() => {
                    this.error = '';
                }, 5000);
                break;
            case 'done':
                const msg = output.file.response;
                const progress = (this.files[0]) ? this.files[0].progress.data.percentage : '';
                if (msg !== '' && !msg.error && msg.status && msg.status.data && msg.status.data.success && progress === 100) {
                    // close modal after successfully upload
                    this._documentApi.findOne({
                        where: {
                            id: msg.status.data.docmnt
                        },
                        include: [
                            { relation: 'category', scope: { fields: ['title', 'id'] } },
                            { relation: 'subCategory', scope: { fields: ['title', 'id'] } }
                        ]
                    }).subscribe(
                        result => {
                            this.returnObj.push(result);
                            this.query.processed++;
                            this.query.success++;
                            if (this.query.total === this.query.processed) {
                                this.uploaded.emit(this.returnObj);
                                if(!this.fromAccountProfile){
                                    this._modalService.closed();                              
                                }
                                this.alertService.clear();
                                this.alertService.success(msg.status.data.success.message);
                            }
                        },
                        err => {
                            this.query.processed++;
                            this.query.success++;
                            this.returnObj.push(err);
                            if (this.query.total === this.query.processed) {
                                this.uploaded.emit(this.returnObj);
                                this._modalService.closed();
                                this.alertService.clear();
                                this.alertService.success(msg.status.data.success.message);
                            }
                        }
                    );
                } else {
                    this.query.processed++;
                    this.query.error++;
                    this.isClose = false;
                    this.error +=
                        (msg.status && msg.status.data.error) ?
                            msg.status.data.error.message :
                            ' Error while uploading ' + output.file.name;
                    if (this.query.total === this.query.processed) {
                        this._modalService.closed();
                        this.alertService.error(this.error);
                    }
                }
                break;
        }
        if(this.source && this.source === 'UserVideo'){
            this.getDocumentByUser();
        }
    }

    startUpload(model): void {
        // set model values when source is MessagePost
        // tslint:disable-next-line:max-line-length
        if(this.source && this.source === 'UserVideo'){
            this.doNoAllowToUpload = false;
        }
        if (this.source && (this.source === 'MessagePost' || this.source === 'Timecard' || this.source === 'Timesheet' || this.source === 'Task-Conversation' || this.source === 'jobOrderItem' || this.source === 'UserVideo'  || this.source === 'documentForTraining' || this.source === 'mwpMessagePost')) {
            model.description = this.document.description;
            model.parentId = this.document.parentId;
            model.categoryId = this.document.categoryId;
        }

        if (this.source && this.source === 'documentForTraining') {
            model.communityId = this.document.communityId;
            model.workerType = this.document.workerType;
            model.departmentId = this.document.departmentId;
        }

        if (this.source && this.source === 'applicationFiles') {
            model.description = this.document.description;
            model.parentId = this.document.parentId;
            model.categoryId = this.document.categoryId;
            this.titles.forEach(item => {
                if(item.title === 'Candidate Resume' && this.document.categoryId == item.id) {
                    this.modelId = this.candidateId;
                    this.modelName = 'Contact';
                } else if(item.title === 'Others' && this.document.categoryId == item.id){
                    this.modelName = 'Application';
                    this.modelId = this.applicationId;
                }
            });
        }

        this.isUploadStarted = true;

        // manually start uploading concurrency: 1 // set sequential uploading of files with concurrency 1
        this.isClose = false;
        this.error = '';
        const event: UploadInput = {
            type: 'uploadAll',
            url:
                environment.baseUrl +
                '/' +
                environment.apiVersion +
                '/Documents/uploadDocument?access_token=' +
                this.token +
                '&modelName=' +
                this.modelName +
                '&cId=' + model.parentId,
            method: 'POST',
            data: {
                modelName: this.modelName,
                modelId: this.modelId,
                description: model.description,
                categoryId: '' + model.parentId + '',
                subCategoryId: '' + model.categoryId + '',
                accountName: this.accountName,
                programName: this.programName,
                fromVendorView : this.fromAccountProfile ? 'true' : 'false',
                serviceNow: 'true'
            }
        };
        if (this.source && this.source === 'documentForTraining') {
            event.data['communityId'] = model.communityId;
            event.data['workerType'] = model.workerType;
            event.data['departmentId'] = model.departmentId;
        }
        if(model.isSentToServiceNow && this.Partner_Case_Number__c && this.Partner_Case_Number__c  !=''){
            event.data['sentToServiceNow'] = model.isSentToServiceNow;
            event.data['Partner_Case_Number__c'] = this.Partner_Case_Number__c
        }
        // console.log(event);
            this.uploadInput.emit(event);
    }

    /**
     * @param event file input
     * for validating file
     */
    forValidationOnly(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            this.isValid = file ? true : false;
        }
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(file, isInvalid): void {
        this.uploadInput.emit({ type: 'remove', id: file.id });
        /* re-validating when all files removed */
        if (this.files.length <= this.MAX_FILE_UPLOAD) {
            this.isValid = true;
            this.error = '';
        }
        if (this.files.length === 0) {
            this.resetFileInput();
        }
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
        this.resetFileInput();
    }

    resetFileInput() {
        this.isClose = true;
        this.isValid = false;
        this.error = ''
        if (this.myInputFile && this.myInputFile.nativeElement) {
            this.myInputFile.nativeElement.value = '';
        }

    }

    // close modal on click because let-c and let-d cant be use inot child component of modal
    closeModal() {
        this.onModalClose.emit('close');
        this._modalService.closed();
    }

    getContentTypes() {
        this.contentTypes = true;
    }

    enableBrowseToUpload() {
        if (this.modelName === 'JobOrderItem') {
            return (this.document.parentId && this.document.categoryId && this.modelId && this.checkAllowedPermission()) ? true : false;
        } else if (this.modelName === 'UserProfile') {
            const canUserUpload = (this.uploadeVideo < this.options.maxUploads) ? true : false;
            return (this.document.parentId && this.document.categoryId && this.checkAllowedPermission() && canUserUpload === true) ? true : false;
        } else {
            return (this.document.parentId && this.document.categoryId && this.checkAllowedPermission()) ? true : false;
        }
    }

    updateModelId = (event) => {
        this.isCategoryDisabled = event ? false : true;
        this.document.parentId = ''
        this.document.categoryId = '';
        this.titles = [];
        this.files = [];
        this.isSubCategoryDisabled = true;
        this.modelId = event;
        this.setPmsAccessPermission()
    }

    checkAllowedPermission(){
     let matchCat = this.allowedCategoryList.find( cat  => cat == this.document.parentId);
      let matchSCat= this.allowedSubCategoryList.find(sCat  => sCat == this.document.categoryId);
      if(matchCat && matchSCat){
        return true;
      }else{
        this.error = "You are not allow to upload file(s).";
        return false;
      }
    }

    getPartnerAccount(){
        this._filterServiceApi.getAllFiltersData({ 'models': ['Account'] }).subscribe(
            data => {
                if (data.data) {
                    data = data.data;
                    if (data['accounts'] && data['accounts']['list']) {
                        this.accounts = data['accounts']['list'];
                    }
                }
            },
            error => {
                this.error = error.message;
            }
        );
    }

    getDepartments(){
        this._departmentApi.find({fields: ['sfdcId', 'Department_Name__c']}).subscribe(
            depart => {
                if (depart && depart.length) {
                    this.departments = depart;
                }
            },
            error => {
                this.error = error.message;
            }
        );
    }

    getPrograms = (accountSfdcId) => {
        if(accountSfdcId) {
        const account = this.accounts.find(ac => ac.sfdcId == accountSfdcId);
        this.accountName = (account && account.Name) ? account.Name :'';
        if(accountSfdcId){
            this._projectApi.find({
                where: {
                    Account__c: accountSfdcId,
                    RecordTypeId: '0121a0000006QJJAA2',
                    Status__c: 'Active',
                    Project__c: { neq: null }
                },
                fields: ['id', 'Name', 'sfdcId', 'Project__c']
            }).subscribe(response => {
                if (response && response.length) {
                    this.projects = response;
                } else {
                    this.projects = [];
                }
            }, error => {
                this.error = error.message;
            });
        }else{
            this.projects = [];
        }
    }

    }

    getProgramName = (event) =>{
        const prg = this.projects.find(p => p.sfdcId == event);
        this.modelId = (prg && prg.id) ? prg.id : '';
        this.programName = (prg && prg.Name) ? prg.Name :'';
    }
    getDocumentByUser() {
        this.isUploadStarted =false;
        const findObj = {
          where: { modelId: this.modelId, isDeleted: { neq: true } }
        }
        const appData = JSON.parse(localStorage.getItem('appData'));
        const userSfdcId = (appData.user && appData.user.sfdcId) ? appData.user.sfdcId : '';
        findObj['where']['context.usersfdcId'] = userSfdcId;
        this._loader.showPreloader();
        this._documentApi.find(findObj).subscribe(result => {
          this._loader.hidePreloader();
          this.docUploadedByUser = result;
          if (this.docUploadedByUser.length >= 3) {
            this.error = 'Already Three File Added'
            this.avoidToUploadForUser = true;
            setTimeout(() => {
                this.onModalClose.emit('close');
            }, 5000);
        }
        },err=>{
          this._loader.hidePreloader();
          console.log(err)
        })
      }


        /**
     * get Sub Categories on category selection.
     */
    getRecruitmentMangerSubCategories() {
        this.document.categoryId = '';
        this.document.files = '';
        this.titles = [];
        this.files = [];
        this.totalFileSize = 0;
        this.contentTypes = false;
        this.removeAllFiles();
        this.isValid = false;
        const findObj = {
            where: {
                isActive: 1,
                documentCategoryId: 20
            }
        }
        this._documentTitleApi
                .find(findObj)
                .subscribe(data => {
                    this.titles = data;
                    this.setAllowedFileTypes(20);
                });
    }

}

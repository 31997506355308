/* tslint:disable */

declare var Object: any;
export interface EmploymentHistoryInterface {
  "sfdcId"?: string;
  "Contact__c"?: string;
  "CreatedById"?: string;
  "CurrencyIsoCode"?: string;
  "Name"?: string;
  "LastModifiedById"?: string;
  "Compensation__c"?: number;
  "Employer__c"?: string;
  "Name__c"?: string;
  "Employment_End_Date__c"?: Date;
  "Job_Title__c"?: string;
  "Location__c"?: string;
  "Parsed__c"?: boolean;
  "Placed__c"?: boolean;
  "Responsibilities__c"?: string;
  "Role__c"?: string;
  "Salaried__c"?: boolean;
  "Employment_Start_Date__c"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class EmploymentHistory implements EmploymentHistoryInterface {
  "sfdcId": string;
  "Contact__c": string;
  "CreatedById": string;
  "CurrencyIsoCode": string;
  "Name": string;
  "LastModifiedById": string;
  "Compensation__c": number;
  "Employer__c": string;
  "Name__c": string;
  "Employment_End_Date__c": Date;
  "Job_Title__c": string;
  "Location__c": string;
  "Parsed__c": boolean;
  "Placed__c": boolean;
  "Responsibilities__c": string;
  "Role__c": string;
  "Salaried__c": boolean;
  "Employment_Start_Date__c": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: EmploymentHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EmploymentHistory`.
   */
  public static getModelName() {
    return "EmploymentHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EmploymentHistory for dynamic purposes.
  **/
  public static factory(data: EmploymentHistoryInterface): EmploymentHistory{
    return new EmploymentHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EmploymentHistory',
      plural: 'EmploymentHistories',
      path: 'EmploymentHistories',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "Compensation__c": {
          name: 'Compensation__c',
          type: 'number'
        },
        "Employer__c": {
          name: 'Employer__c',
          type: 'string'
        },
        "Name__c": {
          name: 'Name__c',
          type: 'string'
        },
        "Employment_End_Date__c": {
          name: 'Employment_End_Date__c',
          type: 'Date'
        },
        "Job_Title__c": {
          name: 'Job_Title__c',
          type: 'string'
        },
        "Location__c": {
          name: 'Location__c',
          type: 'string'
        },
        "Parsed__c": {
          name: 'Parsed__c',
          type: 'boolean'
        },
        "Placed__c": {
          name: 'Placed__c',
          type: 'boolean'
        },
        "Responsibilities__c": {
          name: 'Responsibilities__c',
          type: 'string'
        },
        "Role__c": {
          name: 'Role__c',
          type: 'string'
        },
        "Salaried__c": {
          name: 'Salaried__c',
          type: 'boolean'
        },
        "Employment_Start_Date__c": {
          name: 'Employment_Start_Date__c',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

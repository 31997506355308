import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { WorkerApi } from 'shared/sdk';
import { filter, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { CommonService } from 'shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-lookup',
  templateUrl: './user-lookup.component.html',
  styleUrls: ['./user-lookup.component.css']
})
export class UserLookupComponent implements OnInit {

  isLoading = false;
  taskMemberIds: any[];

  @Input() set resetData(isClear) {
    // this.usersList = [];
    this._userSelected = [];
  }
  @Input()
  set isMultipleSelected(e) {
    this._isMultipleSelected = e;
    this.maxSelectedItems = e ? 20 : 1;
  }
  @Input()
  set excludExistingUserIds(e) {
    if(e) {
      this.taskMemberIds = e;
    }
  }
  @Input() hideSelected = false;
  @Output() getUserObj: EventEmitter<any> = new EventEmitter;
  noUserFound = 'No user found.';
  usersTypeahead = new EventEmitter<string>();
  usersList = [];
  @Input() 
  set userSelected(e) {
    this._userSelected = e;
    this.getSelectedUserData();
  }
  @Input() isGlobalFilter = true;
  @Input() boxedLayout = false;
  @Input() filterObjPropertyName = 'users';
  @Input() workerType = 'MSP User';

  maxSelectedItems = 20;
  _isMultipleSelected = true;
  _userSelected = [];
  bindvalue = "sfdcId";

  constructor(
    private _cd: ChangeDetectorRef,
    private _workerApi: WorkerApi,
    private _commonService: CommonService,
    private router: Router
  ) {
    this._commonService.getLeftSideFilters().subscribe(leftFilter => {
      if (leftFilter && leftFilter.selectedTab === 'globalTask' && this.filterObjPropertyName === 'users') {
        this._userSelected = leftFilter.selectedData;
        this.getSelectedUserData();
      }
    })
  }

  ngOnInit() {
    if(this.router.url == '/app/analytics/serviceo-chat-analytics') {
      this.bindvalue = "Contact__c";
    }
    this.getUsers();
    // to show selected user
    if (this.isGlobalFilter) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this._userSelected = (preselected && preselected[this.filterObjPropertyName]) ? preselected[this.filterObjPropertyName] : '';
    }
    this.getSelectedUserData();
  }

  getSelectedUserData() {
    if (this._userSelected && this._userSelected.length) {
      let whereObj = {
        sfdcId: Array.isArray(this._userSelected) ? { inq: this._userSelected } : this._userSelected,
        ...(this.workerType ? { Worker_Type__c: this.workerType } : {})
      };

      this.getUsersList(whereObj).subscribe(
        x => {
          this.isLoading = false;
          this.usersList = x;
          this.noUserFound = (this.usersList.length === 0) ? 'No User found.' : '';
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.usersList = [];
          this.noUserFound = '';
        }
      );
    }
    this.getUserObj.emit(this._userSelected);
  }

  // get partner's accounts list by name
  getUsers() {
    this.usersList = [];
    this.usersTypeahead
      .pipe(
        filter(term => term && term.trim().length > 2),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(term => {
          const searchPattern = { ilike: '%' + term.trim() + '%', options: 'i' };
          const whereObj = {
            Name: searchPattern,
            ...(this.workerType ? { Worker_Type__c: this.workerType } : {}),
            ...(this.taskMemberIds ? { sfdcId: { nin: this.taskMemberIds }} : {})
          };
          return this.getUsersList(whereObj);
        })
      )
      .subscribe(
        x => {
          this.isLoading = false;
          this._cd.markForCheck();
          this.usersList = x;
          this.noUserFound = (this.usersList.length === 0) ? 'No User found.' : '';
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.usersList = [];
          this.noUserFound = '';
        }
      );
      if(this._commonService.userSelected){
        this.usersList = this._commonService.userSelected;
      }
  }

  onUsersChange(event?) {
    this.getUserObj.emit(this._userSelected);
    if(event){
      this._commonService.userSelected = event;
    }
  }

  clearUsers() {
    this._userSelected = [];
    this.getUserObj.emit([]);
  }

  /**
   * 
   * @param whereObj 
   */

  getUsersList(whereObj) {    
    this.isLoading = true;
    let includeObj = {}
    if(this.taskMemberIds){
       includeObj = {
        include: [
        {
          relation : 'contact',
          scope : {
            where: {
                and: [
                    { Disable_PMS_Portal_Login__c: {neq: true} }
                ]
               },
            fields : ['sfdcId', 'Active__c', 'Disable_PMS_Portal_Login__c', 'Department', 'departmentId'],
            include: [
              {
                relation: 'department',
                scope: {
                  fields: ['sfdcId', 'Department_Acronym__c' ]
                }
              },
            ],
          }
        }
      ]}
    } else {
      includeObj = {
        include: [
          {
            relation: 'contact',
            scope: {
              where: {
                and: [
                    { Disable_PMS_Portal_Login__c: {neq: true} }
                ]
               },
              fields: ['sfdcId', 'Active__c', 'Department', 'departmentId', 'Disable_PMS_Portal_Login__c'],
              include: [
                {
                  relation: 'department',
                  scope: {
                    fields: ['sfdcId', 'Department_Acronym__c']
                  }
                },
              ],
            }
          }
        ]
      }
    }
    return this._workerApi.find({
      where: whereObj,
      fields: ['Name', 'id', 'sfdcId', 'Contact__c'],
      ...includeObj,
      order: 'CreatedDate DESC',
    }).map(res => {
      if(res && this.taskMemberIds) {
       return res.filter(element => 
          element['contact'] && element['contact']['Disable_PMS_Portal_Login__c'] === false
        ).map(element => element);
      } else {
        return res;
      };
    })
  }

}



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class NotificationService {
  notificationUrl = environment.notificationUrl + '/notifications';
  activityApiUrl = environment.notificationUrl + '/activity';
  schedulerApiUrl = environment.notificationUrl + '/schedule-sms';

  constructor(
    public http: HttpClient,

  ) {

  }

   /**
   * Load all Schedule sms
   * @param pageNo Number of pages on Schedule sms
   * @param perPage number of records to fetch per page.
   */
   getScheduleSMS(pageNo = 1, filter = {}, perPage = 200, exportToExcel = false, order = 'created_at', orderType='desc') {
    return new Promise((resolve, reject) => {
      let urlString = this.schedulerApiUrl + '/list';
      if(exportToExcel){
        urlString += '?exportData=true' 
      } else {
        urlString += '?page=' + pageNo;
        if (perPage) {
          urlString = urlString.concat(`&limit=${perPage}`);
        }
      }
      if (order) {
        urlString = urlString.concat(`&order=${order}`);
      }
      if (orderType) {
        urlString = urlString.concat(`&orderType=${orderType}`);
      }
      this.http.post(urlString, {filters: filter}).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }
  sendSMS(smsData) {
    return new Promise((resolve, reject) => {
      let urlString = this.schedulerApiUrl + '/send-sms';
      this.http.post(urlString, smsData).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }

  sendNow(smsData) {
    return new Promise((resolve, reject) => {
      let urlString = this.schedulerApiUrl + '/send-now';
      this.http.post(urlString, smsData).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }
  /**
    * Load activityPage
    * @param pageNo Number of pages on activity page. By default is 1.
    * @param perPage - number of records to fetch per page. By default is 20.
  */
  getActivityFeed(pageNo, filter, perPage?) {
    return new Promise((resolve, reject) => {
      let urlString = this.activityApiUrl + '/filter?page=' + pageNo;
      if (filter && filter.Type === 'case_activity') {
        filter = { 'case_filter': filter }
      } else if (filter && filter.Type === 'activityPerCase') {
        filter = { 'activityFilter': filter }
      }
      else if (filter && filter.Type === 'Timecard') {
        delete filter.Type;
        filter = { 'timecardFilter': filter }
        urlString = this.activityApiUrl + '/timecard-feeds?page=' + pageNo;
      } else if (filter && filter.Type === 'Account') {
        delete filter.Type;
        filter = { 'accountFilter': filter }
        urlString = this.activityApiUrl + '/account-feeds?page=' + pageNo;
      } else {
        filter = { 'jobFilter': filter }
      }
      if (filter && filter.timecardFilter && filter.timecardFilter.Type === 'Timecard') {
        urlString = this.activityApiUrl + '/timecard-feeds?page=' + pageNo;
      }
      if (perPage) {
        urlString = urlString.concat(`&perPage=${perPage}`);
      }
      this.http.post(urlString, filter).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }
  /**
         * Load activityPage
         * @param pageNo Number of pages on activity page. By default is 1.
         */
  getActivityByJob(pageNo, limit, filter = {}, jobFilter?) {
    if (jobFilter.activityType === 'case_activity') {
      filter = { 'where': filter, ...(jobFilter && { 'caseFilter': jobFilter }) };
    } else {
      filter = { 'where': filter, ...(jobFilter && { 'jobFilter': jobFilter }) };
    }
    return new Promise((resolve, reject) => {
      this.http.post(this.activityApiUrl + '/activityByJob?page=' + pageNo + '&limit=' + limit, filter).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }

  getServiceManagerActivityFeed(pageNo, filter = {}) {
    filter = { filter: filter };
    return new Promise((resolve, reject) => {
      this.http.post(this.activityApiUrl + '/service-manager-feeds?page=' + pageNo, filter).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }

  /**
   * Http get request to fetch the activity feed for the task
   * @param filter
   */
  getTaskActivityFeed(pageNo, filter = {}, perPage = 10) {
    filter = { filter: filter };
    return new Promise((resolve, reject) => {
      this.http.post(this.activityApiUrl + `/task-conversation-feeds?page=${pageNo}&perPage=${perPage}`, filter).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }
  /**
   * Load notification
   * @param pageNo Number of pages on notification page. By default is 1.
  */
  getNotifications(pageNo, limit, filter, jobFilter?) {
    if (filter.accessType === 'partner') {
      delete filter.accessType;
      filter = { 'where': filter}
    } else {
      filter = { 'where': filter, ...(jobFilter && { 'jobFilter': jobFilter }) };
    }
    return new Promise((resolve, reject) => {
      this.http.post(this.notificationUrl + '/filter?page=' + pageNo + '&limit=' + limit, filter).subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }

  /**
   * It's read comment by selected notification id.
   * @param id It's Db Id.
   */
  readByactivityId(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.notificationUrl + '/mark-read?id=' + id).subscribe(
        data => {
          resolve(data)
        },
        err => reject(err)
      );
    });
  }

  /**
   * It's read all Notification for current user.
   */
  readAll() {
    return new Promise((resolve, reject) => {
      this.http.get(this.notificationUrl + '/mark-read-all').subscribe(
        data => {
          resolve(data)
        },
        err => {
          reject(err)
        }
      );
    });
  }

  /**
   * It's return all unread count
   */
  getunReadCount() {
    return new Promise((resolve, reject) => {
      this.http.get(this.notificationUrl + '/unread-count').subscribe(
        data => resolve(data),
        err => reject(err)
      );
    });
  }

}

/* tslint:disable */

declare var Object: any;
export interface EducationHistoryInterface {
  "sfdcId"?: string;
  "Complete__c"?: boolean;
  "Contact__c"?: string;
  "DegreePicklist__c"?: string;
  "Degree__c"?: string;
  "GraduationDate__c"?: Date;
  "Graduation_Year__c"?: string;
  "Major__c"?: string;
  "Parsed__c"?: boolean;
  "School__c"?: string;
  "Name__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class EducationHistory implements EducationHistoryInterface {
  "sfdcId": string;
  "Complete__c": boolean;
  "Contact__c": string;
  "DegreePicklist__c": string;
  "Degree__c": string;
  "GraduationDate__c": Date;
  "Graduation_Year__c": string;
  "Major__c": string;
  "Parsed__c": boolean;
  "School__c": string;
  "Name__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: EducationHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EducationHistory`.
   */
  public static getModelName() {
    return "EducationHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EducationHistory for dynamic purposes.
  **/
  public static factory(data: EducationHistoryInterface): EducationHistory{
    return new EducationHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EducationHistory',
      plural: 'EducationHistories',
      path: 'EducationHistories',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Complete__c": {
          name: 'Complete__c',
          type: 'boolean'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "DegreePicklist__c": {
          name: 'DegreePicklist__c',
          type: 'string'
        },
        "Degree__c": {
          name: 'Degree__c',
          type: 'string'
        },
        "GraduationDate__c": {
          name: 'GraduationDate__c',
          type: 'Date'
        },
        "Graduation_Year__c": {
          name: 'Graduation_Year__c',
          type: 'string'
        },
        "Major__c": {
          name: 'Major__c',
          type: 'string'
        },
        "Parsed__c": {
          name: 'Parsed__c',
          type: 'boolean'
        },
        "School__c": {
          name: 'School__c',
          type: 'string'
        },
        "Name__c": {
          name: 'Name__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

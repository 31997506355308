/* tslint:disable */

declare var Object: any;
export interface QuestionsInterface {
  "sfdcId"?: string;
  "Legacy_QuestionID__c"?: string;
  "IsMulti__c"?: boolean;
  "Optional__c"?: boolean;
  "Order__c"?: number;
  "Question__c"?: string;
  "Question_Set__c"?: string;
  "Question_Size__c"?: string;
  "Rich_Text_Question__c"?: string;
  "IsTxt__c"?: boolean;
  "LastActivityDate"?: Date;
  "Question_Line__c"?: string;
  "Answer_Sequence__c"?: string;
  "Correct_Boolean__c"?: string;
  "Correct_Number__c"?: number;
  "Description_RTA__c"?: string;
  "Description__c"?: string;
  "Type__c"?: string;
  "Marks__c"?: number;
  "CreatedBy__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Questions implements QuestionsInterface {
  "sfdcId": string;
  "Legacy_QuestionID__c": string;
  "IsMulti__c": boolean;
  "Optional__c": boolean;
  "Order__c": number;
  "Question__c": string;
  "Question_Set__c": string;
  "Question_Size__c": string;
  "Rich_Text_Question__c": string;
  "IsTxt__c": boolean;
  "LastActivityDate"?: Date;
  "Question_Line__c"?: string;
  "Answer_Sequence__c"?: string;
  "Correct_Boolean__c"?: string;
  "Correct_Number__c"?: number;
  "Description_RTA__c"?: string;
  "Description__c"?: string;
  "Type__c"?: string;
  "Marks__c"?: number;
  "CreatedBy__c"?: string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: QuestionsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Questions`.
   */
  public static getModelName() {
    return "Questions";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Questions for dynamic purposes.
  **/
  public static factory(data: QuestionsInterface): Questions{
    return new Questions(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Questions',
      plural: 'Questions',
      path: 'Questions',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Legacy_QuestionID__c": {
          name: 'Legacy_QuestionID__c',
          type: 'string'
        },
        "IsMulti__c": {
          name: 'IsMulti__c',
          type: 'boolean'
        },
        "Optional__c": {
          name: 'Optional__c',
          type: 'boolean'
        },
        "Order__c": {
          name: 'Order__c',
          type: 'number'
        },
        "Question__c": {
          name: 'Question__c',
          type: 'string'
        },
        "Question_Set__c": {
          name: 'Question_Set__c',
          type: 'string'
        },
        "Question_Size__c": {
          name: 'Question_Size__c',
          type: 'string'
        },
        "Rich_Text_Question__c": {
          name: 'Rich_Text_Question__c',
          type: 'string'
        },
        "IsTxt__c": {
          name: 'IsTxt__c',
          type: 'boolean'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: "date"
        },
        "Question_Line__c": {
          name: 'Question_Line__c',
          type: "string"
        },
        "Answer_Sequence__c": {
          name: 'Answer_Sequence__c',
          type: "string"
        },
        "Correct_Boolean__c": {
          name: 'Correct_Boolean__c',
          type: "string"
        },
        "Correct_Number__c":{
          name: 'Correct_Number__c',
          type: "number"
        },
        "Description_RTA__c":{
          name: 'Description_RTA__c',
          type: "string"
        },
        "Description__c":{
          name: 'Description__c',
          type: "string"
        },
        "Type__c":{
          name: 'Type__c',
          type: "string"
        },
        "Marks__c":{
          name: 'Marks__c',
          type: "number"
        },
        "Last_ModifiedBy__c":{
          name: 'Last_ModifiedBy__c',
          type: "string"
        },
        "Map_to_Application__c":{
          name: 'Map_to_Application__c',
          type: "string"
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}

/* tslint:disable */
import {
  Account,
  Contact,
  GeoMetro,
  Task,
  Worker,
  CountryCode,
  JobOffers,
  JobOrder,
  Source,
  ApplicationAssessment,
  SOWDetail
} from '../index';

declare var Object: any;
export interface JobApplicationInterface {
  "sfdcId"?: string;
  "Applied_Date_on_Taleo__c"?: Date;
  "Create_Service_Contract__c"?: boolean;
  "CreatedById"?: string;
  "CreatedBy__c"?: string;
  "CreatedDate"?: Date;
  "CurrencyIsoCode"?: string;
  "Driving_Distance_From_Jobsite__c"?: number;
  "Driving_Time__c"?: string;
  "GEO_Code__c"?: string;
  "Hiring_Manager__c"?: string;
  "IsDeleted"?: boolean;
  "Jobsite__c"?: string;
  "LastActivityDate"?: Date;
  "LastModifiedById"?: string;
  "LastModifiedBy__c"?: string;
  "LastModifiedDate"?: Date;
  "LastReferencedDate"?: Date;
  "LastViewedDate"?: Date;
  "Name"?: string;
  "Overall_Health_for_Service_Contract__c"?: boolean;
  "Placement_Reference__c"?: string;
  "Primary_Recruiter__c"?: string;
  "Product__c"?: string;
  "Project_Name__c"?: string;
  "RecordTypeId"?: string;
  "Secondary_Recruiter__c"?: string;
  "Sent_Email__c"?: boolean;
  "Service_Contract_Created__c"?: boolean;
  "Talent_Type__c"?: string;
  "Taleo_Job_Id_Ref__c"?: string;
  "AccountContact__c"?: string;
  "Account__c"?: string;
  "Address__c"?: string;
  "Agency__c"?: string;
  "App_Status__c"?: string;
  "App_Tags__c"?: string;
  "Application_Source__c"?: string;
  "Application_Status__c"?: string;
  "Apply_Agency__c"?: boolean;
  "Apply_Exception__c"?: boolean;
  "Candidate_Contact__c"?: string;
  "Candidate_Source__c"?: string;
  "Client__c"?: string;
  "Code__c"?: string;
  "Completed__c"?: boolean;
  "Contact_Email__c"?: string;
  "Contact_Phone__c"?: string;
  "Contact__c"?: string;
  "Cover_Letter__c"?: string;
  "Custom_Email_Message__c"?: string;
  "Date_Available__c"?: Date;
  "Disposition_Value__c"?: string;
  "EEO_Disabled_Received__c"?: string;
  "EEO_Disabled__c"?: string;
  "EEO_Gender_Received__c"?: string;
  "EEO_Gender__c"?: string;
  "EEO_Race_Received__c"?: string;
  "EEO_Veteran_Status_Received__c"?: string;
  "Email__c"?: string;
  "Exception_Reason_Other__c"?: string;
  "Exception_Reason__c"?: string;
  "Experience_Sum__c"?: string;
  "HiringManager__c"?: string;
  "Internally_Applied__c"?: boolean;
  "Invite_to_Apply__c"?: boolean;
  "Job_Status_JS__c"?: string;
  "Job__c"?: string;
  "Legacy_ApplicationID__c"?: string;
  "Mobile__c"?: string;
  "Overall_Reject_Detail__c"?: string;
  "Overall_Reject_Reason__c"?: string;
  "Phone__c"?: string;
  "Prescreen_Score__c"?: number;
  "PrimaryRecruiter__c"?: string;
  "Race__c"?: string;
  "Rank__c"?: string;
  "Reject_Detail__c"?: string;
  "Reject_Reason__c"?: string;
  "Request_EEO__c"?: boolean;
  "Revert_Status__c"?: string;
  "Rollover_Job_Order__c"?: string;
  "Rollover__c"?: boolean;
  "Score__c"?: number;
  "SecondaryRecruiter__c"?: string;
  "Stage__c"?: string;
  "Status__c"?: string;
  "Tracking_Source__c"?: string;
  "Veteran_Status__c"?: string;
  "referral_id__c"?: string;
  "referred_by__c"?: string;
  "Application_Matched__c"?: boolean;
  "Overall_SubStatus_API_Name__c"?: string;
  "Overall_SubStatus_Id__c"?: string;
  "Resume_Uploaded__c"?: string;
  "Substatus__c"?: string;
  "Translated_Substatus__c"?: string;
  "candidate_currency__c"?: string;
  "candidate_salary__c"?: string;
  "candidate_salary_term__c"?: string;
  "Job_Order_Open__c"?: string;
  "notice_period__c"?: string;
  "Overall_Health_Check__c"?: string;
  "Pay_Rate__c"?: string;
  "Feedback_Flag__c"?: boolean;
  "Interview_Feedback__c"?: string;
  "IsPlacementStarted__c"?: boolean;
  "Job_Stability_Rating_Health_Check__c"?: string;
  "Job_Stability_Rating__c"?: number;
  "LinkedIn_Profile_Health__c"?: string;
  "Stability_Long_Health__c"?: string;
  "Stability_Short_Health__c"?: string;
  "Template_Format_Formula__c"?: string;
  "Candidate_Vendor_Pay_Rate_Negotiatio__c"?: number;
  "Candidate_Type__c"?: string;
  "Reason__c"?: string;
  "Aptitude_Test_Score__c"?: number;
  "English_Test_Score__c"?: number;
  "Can_candidate_Relocate_to_Jobsite__c"?: string;
  "Candidate_Prescreening_Health__c"?: boolean;
  "Candidate_Prescreening_Health_Check__c"?: string;
  "Employment_Type__c"?: string;
  "English_Language_Proficiency__c"?: string;
  "Local_Language__c"?: string;
  "Local_Language_Proficiency__c"?: string;
  "Type_of_work_Visa_authorization__c"?: string;
  "Linkedin_Invite_Status__c"?: string;
  "Prescreening_created_Date_and_Time__c"?: Date;
  "Submit_for_Financal_Approval_Date_Time__c"?: Date;
  "Finance_Approval__c"?: string;
  "Finance_Approval_Status__c"?: string;
  "Finance_Approver_Name__c"?: string;
  "Custom_Price__c"?: boolean;
  "Gross_Margin__c"?: number;
  "Vendor_Annual_Rate__c"?: number;
  "Vendor_FTE_Daily_Rate_Standard__c"?: number;
  "Vendor_FTE_Half_Day_Rate__c"?: number;
  "Vendor_FTE_Hourly_Rate_Holiday__c"?: number;
  "Vendor_FTE_Hourly_Rate_Overtime__c"?: number;
  "Vendor_FTE_Monthly_Rate__c"?: number;
  "Vendor_Hourly_Rate__c"?: number;
  "GrossMargin__c"?: number;
  "Vendor_FTE_Weekly_Rate__c"?: number;
  "TPMO_Approval_Check__c"?: boolean;
  "TPMO_Approval__c"?: string;
  "Joining_Bonus__c"?: number;
  "MBO_Bonus__c"?: number;
  "Other_Bonus__c"?: number;
  "Offered_Vacation_PTOs__c"?: number;
  "Offered_Sick_Leaves__c"?: number;
  "Public_holidays__c"?: number;
  "Health_Insurance__c"?: string;
  "Health_Insurance_Contribution__c"?: string;
  "Any_Other_Benefit__c"?: string;
  "Does_this_candidate_now_or_in_future_nee__c"?: string;
  "Job_Status__c"?: string;
  "Hiring_Entity_Name__c"?: string;
  "Hiring_Entity_Country__c"?: string;
  "Relocation_Expense_In_Any__c"?: number;
  "Standard_Benefit_Packet__c"?: string;
  "Dental_Coverage__c"?: number;
  "Budget_for_Counter_Offer__c"?: number;
  "Medical_Coverage__c"?: number;
  "Vision_Coverage__c"?: number;
  "SOW_Detail__c"?: string;
  "RTR_End_Date__c"?: Date;
  "RTR_Start_Date__c"?: Date;
  "FTE_Employment_Category__c"?: string;
  "Bypass_RTR__c"?: boolean;
  "Candidate_Vaccination_Status__c"?: string;
  "Bypass_Covid_Vaccination_Check__c"?: boolean;
  "Reason_for_Covid_Vaccination_Bypass__c"?: string;
  "Right_to_Represent__c"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  contact?: Contact;
  hrManager?: Contact;
  geoMetro?: GeoMetro;
  createdBy?: Contact;
  agency?: Account;
  tasks?: Task[];
  primaryRecruiter?: Contact;
  lastModifiedBy?: Contact;
  worker?: Worker;
  hiringEntityCountry?: CountryCode;
  jobOffers?: JobOffers[];
  jobOrder?: JobOrder;
  source?: Source;
  applicationAssesment?: ApplicationAssessment[];
  vendorSows?: SOWDetail;
}

export class JobApplication implements JobApplicationInterface {
  "sfdcId": string;
  "Applied_Date_on_Taleo__c": Date;
  "Create_Service_Contract__c": boolean;
  "CreatedById": string;
  "CreatedBy__c": string;
  "CreatedDate": Date;
  "CurrencyIsoCode": string;
  "Driving_Distance_From_Jobsite__c": number;
  "Driving_Time__c": string;
  "GEO_Code__c": string;
  "Hiring_Manager__c": string;
  "IsDeleted": boolean;
  "Jobsite__c": string;
  "LastActivityDate": Date;
  "LastModifiedById": string;
  "LastModifiedBy__c": string;
  "LastModifiedDate": Date;
  "LastReferencedDate": Date;
  "LastViewedDate": Date;
  "Name": string;
  "Overall_Health_for_Service_Contract__c": boolean;
  "Placement_Reference__c": string;
  "Primary_Recruiter__c": string;
  "Product__c": string;
  "Project_Name__c": string;
  "RecordTypeId": string;
  "Secondary_Recruiter__c": string;
  "Sent_Email__c": boolean;
  "Service_Contract_Created__c": boolean;
  "Talent_Type__c": string;
  "Taleo_Job_Id_Ref__c": string;
  "AccountContact__c": string;
  "Account__c": string;
  "Address__c": string;
  "Agency__c": string;
  "App_Status__c": string;
  "App_Tags__c": string;
  "Application_Source__c": string;
  "Application_Status__c": string;
  "Apply_Agency__c": boolean;
  "Apply_Exception__c": boolean;
  "Candidate_Contact__c": string;
  "Candidate_Source__c": string;
  "Client__c": string;
  "Code__c": string;
  "Completed__c": boolean;
  "Contact_Email__c": string;
  "Contact_Phone__c": string;
  "Contact__c": string;
  "Cover_Letter__c": string;
  "Custom_Email_Message__c": string;
  "Date_Available__c": Date;
  "Disposition_Value__c": string;
  "EEO_Disabled_Received__c": string;
  "EEO_Disabled__c": string;
  "EEO_Gender_Received__c": string;
  "EEO_Gender__c": string;
  "EEO_Race_Received__c": string;
  "EEO_Veteran_Status_Received__c": string;
  "Email__c": string;
  "Exception_Reason_Other__c": string;
  "Exception_Reason__c": string;
  "Experience_Sum__c": string;
  "HiringManager__c": string;
  "Internally_Applied__c": boolean;
  "Invite_to_Apply__c": boolean;
  "Job_Status_JS__c": string;
  "Job__c": string;
  "Legacy_ApplicationID__c": string;
  "Mobile__c": string;
  "Overall_Reject_Detail__c": string;
  "Overall_Reject_Reason__c": string;
  "Phone__c": string;
  "Prescreen_Score__c": number;
  "PrimaryRecruiter__c": string;
  "Race__c": string;
  "Rank__c": string;
  "Reject_Detail__c": string;
  "Reject_Reason__c": string;
  "Request_EEO__c": boolean;
  "Revert_Status__c": string;
  "Rollover_Job_Order__c": string;
  "Rollover__c": boolean;
  "Score__c": number;
  "SecondaryRecruiter__c": string;
  "Stage__c": string;
  "Status__c": string;
  "Tracking_Source__c": string;
  "Veteran_Status__c": string;
  "referral_id__c": string;
  "referred_by__c": string;
  "Application_Matched__c": boolean;
  "Overall_SubStatus_API_Name__c": string;
  "Overall_SubStatus_Id__c": string;
  "Resume_Uploaded__c": string;
  "Substatus__c": string;
  "Translated_Substatus__c": string;
  "candidate_currency__c": string;
  "candidate_salary__c": string;
  "candidate_salary_term__c": string;
  "Job_Order_Open__c": string;
  "notice_period__c": string;
  "Overall_Health_Check__c": string;
  "Pay_Rate__c": string;
  "Feedback_Flag__c": boolean;
  "Interview_Feedback__c": string;
  "IsPlacementStarted__c": boolean;
  "Job_Stability_Rating_Health_Check__c": string;
  "Job_Stability_Rating__c": number;
  "LinkedIn_Profile_Health__c": string;
  "Stability_Long_Health__c": string;
  "Stability_Short_Health__c": string;
  "Template_Format_Formula__c": string;
  "Candidate_Vendor_Pay_Rate_Negotiatio__c": number;
  "Candidate_Type__c": string;
  "Reason__c": string;
  "Aptitude_Test_Score__c": number;
  "English_Test_Score__c": number;
  "Can_candidate_Relocate_to_Jobsite__c": string;
  "Candidate_Prescreening_Health__c": boolean;
  "Candidate_Prescreening_Health_Check__c": string;
  "Employment_Type__c": string;
  "English_Language_Proficiency__c": string;
  "Local_Language__c": string;
  "Local_Language_Proficiency__c": string;
  "Type_of_work_Visa_authorization__c": string;
  "Linkedin_Invite_Status__c": string;
  "Prescreening_created_Date_and_Time__c": Date;
  "Submit_for_Financal_Approval_Date_Time__c": Date;
  "Finance_Approval__c": string;
  "Finance_Approval_Status__c": string;
  "Finance_Approver_Name__c": string;
  "Custom_Price__c": boolean;
  "Gross_Margin__c": number;
  "Vendor_Annual_Rate__c": number;
  "Vendor_FTE_Daily_Rate_Standard__c": number;
  "Vendor_FTE_Half_Day_Rate__c": number;
  "Vendor_FTE_Hourly_Rate_Holiday__c": number;
  "Vendor_FTE_Hourly_Rate_Overtime__c": number;
  "Vendor_FTE_Monthly_Rate__c": number;
  "Vendor_Hourly_Rate__c": number;
  "GrossMargin__c": number;
  "Vendor_FTE_Weekly_Rate__c": number;
  "TPMO_Approval_Check__c": boolean;
  "TPMO_Approval__c": string;
  "Joining_Bonus__c": number;
  "MBO_Bonus__c": number;
  "Other_Bonus__c": number;
  "Offered_Vacation_PTOs__c": number;
  "Offered_Sick_Leaves__c": number;
  "Public_holidays__c": number;
  "Health_Insurance__c": string;
  "Health_Insurance_Contribution__c": string;
  "Any_Other_Benefit__c": string;
  "Does_this_candidate_now_or_in_future_nee__c": string;
  "Job_Status__c": string;
  "Hiring_Entity_Name__c": string;
  "Hiring_Entity_Country__c": string;
  "Relocation_Expense_In_Any__c": number;
  "Standard_Benefit_Packet__c": string;
  "Dental_Coverage__c": number;
  "Budget_for_Counter_Offer__c": number;
  "Medical_Coverage__c": number;
  "Vision_Coverage__c": number;
  "SOW_Detail__c": string;
  "RTR_End_Date__c": Date;
  "RTR_Start_Date__c": Date;
  "FTE_Employment_Category__c": string;
  "Bypass_RTR__c": boolean;
  "Candidate_Vaccination_Status__c": string;
  "Bypass_Covid_Vaccination_Check__c": boolean;
  "Reason_for_Covid_Vaccination_Bypass__c": string;
  "Right_to_Represent__c": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  contact: Contact;
  hrManager: Contact;
  geoMetro: GeoMetro;
  createdBy: Contact;
  agency: Account;
  tasks: Task[];
  primaryRecruiter: Contact;
  lastModifiedBy: Contact;
  worker: Worker;
  hiringEntityCountry: CountryCode;
  jobOffers: JobOffers[];
  jobOrder: JobOrder;
  source: Source;
  applicationAssesment: ApplicationAssessment[];
  vendorSows: SOWDetail;
  constructor(data?: JobApplicationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `JobApplication`.
   */
  public static getModelName() {
    return "JobApplication";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of JobApplication for dynamic purposes.
  **/
  public static factory(data: JobApplicationInterface): JobApplication{
    return new JobApplication(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'JobApplication',
      plural: 'JobApplications',
      path: 'JobApplications',
      idName: 'id',
      properties: {
        "sfdcId": {
          name: 'sfdcId',
          type: 'string'
        },
        "Applied_Date_on_Taleo__c": {
          name: 'Applied_Date_on_Taleo__c',
          type: 'Date'
        },
        "Create_Service_Contract__c": {
          name: 'Create_Service_Contract__c',
          type: 'boolean',
          default: false
        },
        "CreatedById": {
          name: 'CreatedById',
          type: 'string'
        },
        "CreatedBy__c": {
          name: 'CreatedBy__c',
          type: 'string'
        },
        "CreatedDate": {
          name: 'CreatedDate',
          type: 'Date'
        },
        "CurrencyIsoCode": {
          name: 'CurrencyIsoCode',
          type: 'string'
        },
        "Driving_Distance_From_Jobsite__c": {
          name: 'Driving_Distance_From_Jobsite__c',
          type: 'number'
        },
        "Driving_Time__c": {
          name: 'Driving_Time__c',
          type: 'string'
        },
        "GEO_Code__c": {
          name: 'GEO_Code__c',
          type: 'string'
        },
        "Hiring_Manager__c": {
          name: 'Hiring_Manager__c',
          type: 'string'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean',
          default: false
        },
        "Jobsite__c": {
          name: 'Jobsite__c',
          type: 'string'
        },
        "LastActivityDate": {
          name: 'LastActivityDate',
          type: 'Date'
        },
        "LastModifiedById": {
          name: 'LastModifiedById',
          type: 'string'
        },
        "LastModifiedBy__c": {
          name: 'LastModifiedBy__c',
          type: 'string'
        },
        "LastModifiedDate": {
          name: 'LastModifiedDate',
          type: 'Date'
        },
        "LastReferencedDate": {
          name: 'LastReferencedDate',
          type: 'Date'
        },
        "LastViewedDate": {
          name: 'LastViewedDate',
          type: 'Date'
        },
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Overall_Health_for_Service_Contract__c": {
          name: 'Overall_Health_for_Service_Contract__c',
          type: 'boolean'
        },
        "Placement_Reference__c": {
          name: 'Placement_Reference__c',
          type: 'string'
        },
        "Primary_Recruiter__c": {
          name: 'Primary_Recruiter__c',
          type: 'string'
        },
        "Product__c": {
          name: 'Product__c',
          type: 'string'
        },
        "Project_Name__c": {
          name: 'Project_Name__c',
          type: 'string'
        },
        "RecordTypeId": {
          name: 'RecordTypeId',
          type: 'string'
        },
        "Secondary_Recruiter__c": {
          name: 'Secondary_Recruiter__c',
          type: 'string'
        },
        "Sent_Email__c": {
          name: 'Sent_Email__c',
          type: 'boolean',
          default: false
        },
        "Service_Contract_Created__c": {
          name: 'Service_Contract_Created__c',
          type: 'boolean',
          default: false
        },
        "Talent_Type__c": {
          name: 'Talent_Type__c',
          type: 'string'
        },
        "Taleo_Job_Id_Ref__c": {
          name: 'Taleo_Job_Id_Ref__c',
          type: 'string'
        },
        "AccountContact__c": {
          name: 'AccountContact__c',
          type: 'string'
        },
        "Account__c": {
          name: 'Account__c',
          type: 'string'
        },
        "Address__c": {
          name: 'Address__c',
          type: 'string'
        },
        "Agency__c": {
          name: 'Agency__c',
          type: 'string'
        },
        "App_Status__c": {
          name: 'App_Status__c',
          type: 'string'
        },
        "App_Tags__c": {
          name: 'App_Tags__c',
          type: 'string'
        },
        "Application_Source__c": {
          name: 'Application_Source__c',
          type: 'string'
        },
        "Application_Status__c": {
          name: 'Application_Status__c',
          type: 'string'
        },
        "Apply_Agency__c": {
          name: 'Apply_Agency__c',
          type: 'boolean',
          default: false
        },
        "Apply_Exception__c": {
          name: 'Apply_Exception__c',
          type: 'boolean',
          default: false
        },
        "Candidate_Contact__c": {
          name: 'Candidate_Contact__c',
          type: 'string'
        },
        "Candidate_Source__c": {
          name: 'Candidate_Source__c',
          type: 'string'
        },
        "Client__c": {
          name: 'Client__c',
          type: 'string'
        },
        "Code__c": {
          name: 'Code__c',
          type: 'string'
        },
        "Completed__c": {
          name: 'Completed__c',
          type: 'boolean',
          default: false
        },
        "Contact_Email__c": {
          name: 'Contact_Email__c',
          type: 'string'
        },
        "Contact_Phone__c": {
          name: 'Contact_Phone__c',
          type: 'string'
        },
        "Contact__c": {
          name: 'Contact__c',
          type: 'string'
        },
        "Cover_Letter__c": {
          name: 'Cover_Letter__c',
          type: 'string'
        },
        "Custom_Email_Message__c": {
          name: 'Custom_Email_Message__c',
          type: 'string'
        },
        "Date_Available__c": {
          name: 'Date_Available__c',
          type: 'Date'
        },
        "Disposition_Value__c": {
          name: 'Disposition_Value__c',
          type: 'string'
        },
        "EEO_Disabled_Received__c": {
          name: 'EEO_Disabled_Received__c',
          type: 'string'
        },
        "EEO_Disabled__c": {
          name: 'EEO_Disabled__c',
          type: 'string'
        },
        "EEO_Gender_Received__c": {
          name: 'EEO_Gender_Received__c',
          type: 'string'
        },
        "EEO_Gender__c": {
          name: 'EEO_Gender__c',
          type: 'string'
        },
        "EEO_Race_Received__c": {
          name: 'EEO_Race_Received__c',
          type: 'string'
        },
        "EEO_Veteran_Status_Received__c": {
          name: 'EEO_Veteran_Status_Received__c',
          type: 'string'
        },
        "Email__c": {
          name: 'Email__c',
          type: 'string'
        },
        "Exception_Reason_Other__c": {
          name: 'Exception_Reason_Other__c',
          type: 'string'
        },
        "Exception_Reason__c": {
          name: 'Exception_Reason__c',
          type: 'string'
        },
        "Experience_Sum__c": {
          name: 'Experience_Sum__c',
          type: 'string'
        },
        "HiringManager__c": {
          name: 'HiringManager__c',
          type: 'string'
        },
        "Internally_Applied__c": {
          name: 'Internally_Applied__c',
          type: 'boolean',
          default: false
        },
        "Invite_to_Apply__c": {
          name: 'Invite_to_Apply__c',
          type: 'boolean',
          default: false
        },
        "Job_Status_JS__c": {
          name: 'Job_Status_JS__c',
          type: 'string'
        },
        "Job__c": {
          name: 'Job__c',
          type: 'string'
        },
        "Legacy_ApplicationID__c": {
          name: 'Legacy_ApplicationID__c',
          type: 'string'
        },
        "Mobile__c": {
          name: 'Mobile__c',
          type: 'string'
        },
        "Overall_Reject_Detail__c": {
          name: 'Overall_Reject_Detail__c',
          type: 'string'
        },
        "Overall_Reject_Reason__c": {
          name: 'Overall_Reject_Reason__c',
          type: 'string'
        },
        "Phone__c": {
          name: 'Phone__c',
          type: 'string'
        },
        "Prescreen_Score__c": {
          name: 'Prescreen_Score__c',
          type: 'number'
        },
        "PrimaryRecruiter__c": {
          name: 'PrimaryRecruiter__c',
          type: 'string'
        },
        "Race__c": {
          name: 'Race__c',
          type: 'string'
        },
        "Rank__c": {
          name: 'Rank__c',
          type: 'string'
        },
        "Reject_Detail__c": {
          name: 'Reject_Detail__c',
          type: 'string'
        },
        "Reject_Reason__c": {
          name: 'Reject_Reason__c',
          type: 'string'
        },
        "Request_EEO__c": {
          name: 'Request_EEO__c',
          type: 'boolean',
          default: false
        },
        "Revert_Status__c": {
          name: 'Revert_Status__c',
          type: 'string'
        },
        "Rollover_Job_Order__c": {
          name: 'Rollover_Job_Order__c',
          type: 'string'
        },
        "Rollover__c": {
          name: 'Rollover__c',
          type: 'boolean',
          default: false
        },
        "Score__c": {
          name: 'Score__c',
          type: 'number'
        },
        "SecondaryRecruiter__c": {
          name: 'SecondaryRecruiter__c',
          type: 'string'
        },
        "Stage__c": {
          name: 'Stage__c',
          type: 'string'
        },
        "Status__c": {
          name: 'Status__c',
          type: 'string'
        },
        "Tracking_Source__c": {
          name: 'Tracking_Source__c',
          type: 'string'
        },
        "Veteran_Status__c": {
          name: 'Veteran_Status__c',
          type: 'string'
        },
        "referral_id__c": {
          name: 'referral_id__c',
          type: 'string'
        },
        "referred_by__c": {
          name: 'referred_by__c',
          type: 'string'
        },
        "Application_Matched__c": {
          name: 'Application_Matched__c',
          type: 'boolean',
          default: false
        },
        "Overall_SubStatus_API_Name__c": {
          name: 'Overall_SubStatus_API_Name__c',
          type: 'string'
        },
        "Overall_SubStatus_Id__c": {
          name: 'Overall_SubStatus_Id__c',
          type: 'string'
        },
        "Resume_Uploaded__c": {
          name: 'Resume_Uploaded__c',
          type: 'string'
        },
        "Substatus__c": {
          name: 'Substatus__c',
          type: 'string'
        },
        "Translated_Substatus__c": {
          name: 'Translated_Substatus__c',
          type: 'string'
        },
        "candidate_currency__c": {
          name: 'candidate_currency__c',
          type: 'string'
        },
        "candidate_salary__c": {
          name: 'candidate_salary__c',
          type: 'string'
        },
        "candidate_salary_term__c": {
          name: 'candidate_salary_term__c',
          type: 'string'
        },
        "Job_Order_Open__c": {
          name: 'Job_Order_Open__c',
          type: 'string'
        },
        "notice_period__c": {
          name: 'notice_period__c',
          type: 'string'
        },
        "Overall_Health_Check__c": {
          name: 'Overall_Health_Check__c',
          type: 'string'
        },
        "Pay_Rate__c": {
          name: 'Pay_Rate__c',
          type: 'string'
        },
        "Feedback_Flag__c": {
          name: 'Feedback_Flag__c',
          type: 'boolean'
        },
        "Interview_Feedback__c": {
          name: 'Interview_Feedback__c',
          type: 'string'
        },
        "IsPlacementStarted__c": {
          name: 'IsPlacementStarted__c',
          type: 'boolean'
        },
        "Job_Stability_Rating_Health_Check__c": {
          name: 'Job_Stability_Rating_Health_Check__c',
          type: 'string'
        },
        "Job_Stability_Rating__c": {
          name: 'Job_Stability_Rating__c',
          type: 'number'
        },
        "LinkedIn_Profile_Health__c": {
          name: 'LinkedIn_Profile_Health__c',
          type: 'string'
        },
        "Stability_Long_Health__c": {
          name: 'Stability_Long_Health__c',
          type: 'string'
        },
        "Stability_Short_Health__c": {
          name: 'Stability_Short_Health__c',
          type: 'string'
        },
        "Template_Format_Formula__c": {
          name: 'Template_Format_Formula__c',
          type: 'string'
        },
        "Candidate_Vendor_Pay_Rate_Negotiatio__c": {
          name: 'Candidate_Vendor_Pay_Rate_Negotiatio__c',
          type: 'number'
        },
        "Candidate_Type__c": {
          name: 'Candidate_Type__c',
          type: 'string'
        },
        "Reason__c": {
          name: 'Reason__c',
          type: 'string'
        },
        "Aptitude_Test_Score__c": {
          name: 'Aptitude_Test_Score__c',
          type: 'number'
        },
        "English_Test_Score__c": {
          name: 'English_Test_Score__c',
          type: 'number'
        },
        "Can_candidate_Relocate_to_Jobsite__c": {
          name: 'Can_candidate_Relocate_to_Jobsite__c',
          type: 'string'
        },
        "Candidate_Prescreening_Health__c": {
          name: 'Candidate_Prescreening_Health__c',
          type: 'boolean'
        },
        "Candidate_Prescreening_Health_Check__c": {
          name: 'Candidate_Prescreening_Health_Check__c',
          type: 'string'
        },
        "Employment_Type__c": {
          name: 'Employment_Type__c',
          type: 'string'
        },
        "English_Language_Proficiency__c": {
          name: 'English_Language_Proficiency__c',
          type: 'string'
        },
        "Local_Language__c": {
          name: 'Local_Language__c',
          type: 'string'
        },
        "Local_Language_Proficiency__c": {
          name: 'Local_Language_Proficiency__c',
          type: 'string'
        },
        "Type_of_work_Visa_authorization__c": {
          name: 'Type_of_work_Visa_authorization__c',
          type: 'string'
        },
        "Linkedin_Invite_Status__c": {
          name: 'Linkedin_Invite_Status__c',
          type: 'string'
        },
        "Prescreening_created_Date_and_Time__c": {
          name: 'Prescreening_created_Date_and_Time__c',
          type: 'Date'
        },
        "Submit_for_Financal_Approval_Date_Time__c": {
          name: 'Submit_for_Financal_Approval_Date_Time__c',
          type: 'Date'
        },
        "Finance_Approval__c": {
          name: 'Finance_Approval__c',
          type: 'string'
        },
        "Finance_Approval_Status__c": {
          name: 'Finance_Approval_Status__c',
          type: 'string'
        },
        "Finance_Approver_Name__c": {
          name: 'Finance_Approver_Name__c',
          type: 'string'
        },
        "Custom_Price__c": {
          name: 'Custom_Price__c',
          type: 'boolean'
        },
        "Gross_Margin__c": {
          name: 'Gross_Margin__c',
          type: 'number'
        },
        "Vendor_Annual_Rate__c": {
          name: 'Vendor_Annual_Rate__c',
          type: 'number'
        },
        "Vendor_FTE_Daily_Rate_Standard__c": {
          name: 'Vendor_FTE_Daily_Rate_Standard__c',
          type: 'number'
        },
        "Vendor_FTE_Half_Day_Rate__c": {
          name: 'Vendor_FTE_Half_Day_Rate__c',
          type: 'number'
        },
        "Vendor_FTE_Hourly_Rate_Holiday__c": {
          name: 'Vendor_FTE_Hourly_Rate_Holiday__c',
          type: 'number'
        },
        "Vendor_FTE_Hourly_Rate_Overtime__c": {
          name: 'Vendor_FTE_Hourly_Rate_Overtime__c',
          type: 'number'
        },
        "Vendor_FTE_Monthly_Rate__c": {
          name: 'Vendor_FTE_Monthly_Rate__c',
          type: 'number'
        },
        "Vendor_Hourly_Rate__c": {
          name: 'Vendor_Hourly_Rate__c',
          type: 'number'
        },
        "GrossMargin__c": {
          name: 'GrossMargin__c',
          type: 'number'
        },
        "Vendor_FTE_Weekly_Rate__c": {
          name: 'Vendor_FTE_Weekly_Rate__c',
          type: 'number'
        },
        "TPMO_Approval_Check__c": {
          name: 'TPMO_Approval_Check__c',
          type: 'boolean'
        },
        "TPMO_Approval__c": {
          name: 'TPMO_Approval__c',
          type: 'string'
        },
        "Joining_Bonus__c": {
          name: 'Joining_Bonus__c',
          type: 'number'
        },
        "MBO_Bonus__c": {
          name: 'MBO_Bonus__c',
          type: 'number'
        },
        "Other_Bonus__c": {
          name: 'Other_Bonus__c',
          type: 'number'
        },
        "Offered_Vacation_PTOs__c": {
          name: 'Offered_Vacation_PTOs__c',
          type: 'number'
        },
        "Offered_Sick_Leaves__c": {
          name: 'Offered_Sick_Leaves__c',
          type: 'number'
        },
        "Public_holidays__c": {
          name: 'Public_holidays__c',
          type: 'number'
        },
        "Health_Insurance__c": {
          name: 'Health_Insurance__c',
          type: 'string'
        },
        "Health_Insurance_Contribution__c": {
          name: 'Health_Insurance_Contribution__c',
          type: 'string'
        },
        "Any_Other_Benefit__c": {
          name: 'Any_Other_Benefit__c',
          type: 'string'
        },
        "Does_this_candidate_now_or_in_future_nee__c": {
          name: 'Does_this_candidate_now_or_in_future_nee__c',
          type: 'string'
        },
        "Job_Status__c": {
          name: 'Job_Status__c',
          type: 'string'
        },
        "Hiring_Entity_Name__c": {
          name: 'Hiring_Entity_Name__c',
          type: 'string'
        },
        "Hiring_Entity_Country__c": {
          name: 'Hiring_Entity_Country__c',
          type: 'string'
        },
        "Relocation_Expense_In_Any__c": {
          name: 'Relocation_Expense_In_Any__c',
          type: 'number'
        },
        "Standard_Benefit_Packet__c": {
          name: 'Standard_Benefit_Packet__c',
          type: 'string'
        },
        "Dental_Coverage__c": {
          name: 'Dental_Coverage__c',
          type: 'number'
        },
        "Budget_for_Counter_Offer__c": {
          name: 'Budget_for_Counter_Offer__c',
          type: 'number'
        },
        "Medical_Coverage__c": {
          name: 'Medical_Coverage__c',
          type: 'number'
        },
        "Vision_Coverage__c": {
          name: 'Vision_Coverage__c',
          type: 'number'
        },
        "SOW_Detail__c": {
          name: 'SOW_Detail__c',
          type: 'string'
        },
        "RTR_End_Date__c": {
          name: 'RTR_End_Date__c',
          type: 'Date'
        },
        "RTR_Start_Date__c": {
          name: 'RTR_Start_Date__c',
          type: 'Date'
        },
        "FTE_Employment_Category__c": {
          name: 'FTE_Employment_Category__c',
          type: 'string'
        },
        "Bypass_RTR__c": {
          name: 'Bypass_RTR__c',
          type: 'boolean',
          default: false
        },
        "Candidate_Vaccination_Status__c": {
          name: 'Candidate_Vaccination_Status__c',
          type: 'string'
        },
        "Bypass_Covid_Vaccination_Check__c": {
          name: 'Bypass_Covid_Vaccination_Check__c',
          type: 'boolean',
          default: false
        },
        "Reason_for_Covid_Vaccination_Bypass__c": {
          name: 'Reason_for_Covid_Vaccination_Bypass__c',
          type: 'string'
        },
        "Right_to_Represent__c": {
          name: 'Right_to_Represent__c',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Account__c',
          keyTo: 'sfdcId'
        },
        contact: {
          name: 'contact',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Candidate_Contact__c',
          keyTo: 'sfdcId'
        },
        hrManager: {
          name: 'hrManager',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Hiring_Manager__c',
          keyTo: 'sfdcId'
        },
        geoMetro: {
          name: 'geoMetro',
          type: 'GeoMetro',
          model: 'GeoMetro',
          relationType: 'belongsTo',
                  keyFrom: 'GEO_Code__c',
          keyTo: 'Name'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'CreatedBy__c',
          keyTo: 'sfdcId'
        },
        agency: {
          name: 'agency',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'Agency__c',
          keyTo: 'sfdcId'
        },
        tasks: {
          name: 'tasks',
          type: 'Task[]',
          model: 'Task',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Application__c'
        },
        primaryRecruiter: {
          name: 'primaryRecruiter',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'Primary_Recruiter__c',
          keyTo: 'sfdcId'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Contact',
          model: 'Contact',
          relationType: 'belongsTo',
                  keyFrom: 'LastModifiedBy__c',
          keyTo: 'sfdcId'
        },
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'Finance_Approver_Name__c',
          keyTo: 'sfdcId'
        },
        hiringEntityCountry: {
          name: 'hiringEntityCountry',
          type: 'CountryCode',
          model: 'CountryCode',
          relationType: 'belongsTo',
                  keyFrom: 'Hiring_Entity_Country__c',
          keyTo: 'sfdcId'
        },
        jobOffers: {
          name: 'jobOffers',
          type: 'JobOffers[]',
          model: 'JobOffers',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Related_Application__c'
        },
        jobOrder: {
          name: 'jobOrder',
          type: 'JobOrder',
          model: 'JobOrder',
          relationType: 'belongsTo',
                  keyFrom: 'Job__c',
          keyTo: 'sfdcId'
        },
        source: {
          name: 'source',
          type: 'Source',
          model: 'Source',
          relationType: 'belongsTo',
                  keyFrom: 'Application_Source__c',
          keyTo: 'sfdcId'
        },
        applicationAssesment: {
          name: 'applicationAssesment',
          type: 'ApplicationAssessment[]',
          model: 'ApplicationAssessment',
          relationType: 'hasMany',
                  keyFrom: 'sfdcId',
          keyTo: 'Application__c'
        },
        vendorSows: {
          name: 'vendorSows',
          type: 'SOWDetail',
          model: 'SOWDetail',
          relationType: 'belongsTo',
                  keyFrom: 'SOW_Detail__c',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
